












































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})
export default class Accordion extends Vue {
  @Prop() items!: {
    isTextFirst?: boolean
    title: string
    text: string
    iconId: string
    link?: string
    image?: string
    linkText?: string
  }[]

  accordionInnerHeight: number[] = []
  isOpenMap: { [index: number]: boolean } = {}

  toggle(index: number, event: Event) {
    event.preventDefault()
    this.isOpenMap = {
      [index]: !this.isOpenMap[index]
    }
    this.calcHeight()
  }

  mounted() {
    this.calcHeight()

    const openQueryIndex = parseInt((this.$route.query?.open || '').toString())

    if (openQueryIndex >= 0) {
      this.$set(this.isOpenMap, openQueryIndex, true)
    }
  }

  calcHeight() {
    this.accordionInnerHeight = this.items.map((_, index) => {
      const [$el] = (this.$refs[`accordionItem${index}`] as Element[])

      return $el ? $el.scrollHeight + 50 : 0
    })
  }
}
