








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SuperButton extends Vue {
  @Prop() text!: string
  @Prop() iconRight!: boolean
  @Prop() iconLeft!: boolean
}

