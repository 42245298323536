export default {
  intro: {
    text: 'Wie wollen wir uns in Zukunft fortbewegen? Welche Entscheidungen müssen wir treffen, um eine Mobilitätswelt zu schaffen, die für uns alle funktioniert und die Umwelt schützt?',
  },
  checkList: {
    overline: 'Das Fundament',
    headline: 'Kerngestaltungs-möglichkeiten',
    text: 'Die vier Zukunftspfade unterscheiden sich in ihrer Ausgestaltung, trotzdem teilen sie sich bestimmte Stellschrauben. Diese sind so wirksam, dass es sich immer lohnt sie einzusetzen. Es gibt aber Spielraum, wie stark an diesen Stellschrauben gedreht wird, und das beeinflusst auch deren Wirkung. Wir nennen diese Stellschrauben „Sockelmaßnahmen“ und stellen sie hier vor, da sie in allen Zukunftspfaden eine mehr oder weniger große Rolle spielen.',
    overlayHeadline: 'Info',
    buttonClose: 'Ok',
    buttonGlossar: 'Im Glossar öffnen',
    items: [
      {
        headline: 'Schärfere Flottengrenzwerte',
        text: 'Die Flottengrenzwerte können bereits ab 2025 verschärft werden und nicht erst ab 2030, wie bisher von der EU geplant. Ab 2030 können die Flottengrenzwerte immer weiter abgesenkt werden, so dass ab einem bestimmten Jahr neue PKW keine Emissionen mehr ausstoßen dürfen.',
      },
      {
        headline: 'CO\u2082-Preis erhöhen + Rückvergütung ',
        text: `
            Auf klimaschädlichen Ausstoß von <a class="link" data-index="co2-kohlenstoffdioxid">Kohlenstoffdioxid</a> kann ein Preis erhoben werden: dann gilt, je höher der Ausstoß, desto höher der Preis. Der sog. <a class="link" data-index="co2-preis">CO\u2082-Preis</a> wird in den Pfaden auf zwei Arten eingesetzt – einmal als begleitende Stellschraube mit einem relativ niedrigen <a class="link" data-index="co2-preis">CO\u2082-Preis</a> (65 - 100€ pro Tonne CO\u2082 im Jahr 2030) oder als die zentrale Stellschraube mit einem sehr hohen Preis (300€ pro Tonne CO\u2082 im Jahr 2030).
            </br></br>
            Gut zu wissen! Wird der <a class="link" data-index="co2-preis">CO\u2082-Preis</a> mit einer Rückvergütung kombiniert, setzt ein überraschender Effekt ein: Haushalte mit niedrigem Einkommen profitieren mehr von einem hohen <a class="link" data-index="co2-preis">CO\u2082-Preis</a> als von einem niedrigen, da sie sich meist klimaschonender verhalten, aber insgesamt über alle Haushalte mehr Geld rückvergütet werden kann.
          `,
      },
      {
        headline: 'Bonus-Malus-System bei Neuzulassung einführen ',
        text: 'Die Pfade unterscheiden sich, indem entweder die Neuzulassung sparsamer PKW durch einen Bonus vergünstigt oder die Neuzulassung CO\u2082-intensiver PKW durch einen Malus verteuert wird. Natürlich können auch beide Möglichkeiten kombiniert werden.',
      },
      {
        headline: 'Fahrleistungsabhängige Maut für PKW einführen',
        text: 'Pro gefahrenem Kilometer auf allen Straßen kann eine feste Gebühr (1 bzw. 4 ct) erhoben werden. Der Staat kann so zukünftig die Ausfälle an Einnahmen ausgleichen, die er sonst über die <a class="link" data-index="energiesteuer">Energiesteuer</a> auf Benzin und Diesel einnimmt. Die <a class="link" data-index="energiesteuer">Energiesteuer</a>, die beim Betrieb eines Elektroautos anfällt, beträgt heute nur weniger als 10 % der <a class="link" data-index="energiesteuer">Energiesteuer</a> eines Verbrenners. Die Antriebswende geht deshalb aktuell mit Einnahmeausfällen für den Staat einher.',
      },
      {
        headline: 'Ladeinfrastruktur ausbauen',
        text: 'Entscheidend ist, bis wann der Ausbau der <a class="link" data-index="ladeinfrastruktur">Ladeinfrastruktur</a> für E-Autos abgeschlossen ist. Alle Pfade haben bis spätestens 2030 einen flächendeckenden Ausbau. ',
      },
      {
        headline: 'Angebot innerdeutscher Flüge anpassen',
        text: `
            Innerdeutsche Flüge profitieren bisher stark von Steuervergünstigungen. Obwohl der CO\u2082-Ausstoß durch den innerdeutschen Flugverkehr nur 2% der CO\u2082-Emissionen des Verkehrs beträgt, sind Flüge im Allgemeinen die klimaschädlichste Form sich fortzubewegen. Zusätzlich ist der Ausstoß von CO\u2082 in großer Höhe klimaschädlicher als am Boden. Um die Klimawirkung des Flugverkehrs zu berücksichtigen können Steuern angepasst bzw. überhaupt erhoben werden:</br>
            - eine Kerosinsteuer kann erhoben werden (aktuell nicht vorhanden),</br>
            - die Luftverkehrssteuer kann erhöht werden und</br>
            - die CO\u2082-Steuer für Kerosin kann stark erhöht werden.</br>
            </br>
            Schließlich können innerdeutsche Flüge auch gänzlich verboten werden, sodass nur noch Flüge ins Ausland erlaubt sind.
          `,
      },
      {
        headline: 'ÖV-/ ÖPNV-/ Radinfrastruktur und -angebot verbessern',
        text: 'Zusätzliche Mittel können vom Bund zur Verfügung gestellt werden, um das Angebot für Bahn und ÖPNV zu verbessern, z.B. schnellere und pünktlichere Busse und Bahnen, höhere Takte, aber auch neue Schienentrassen für Züge. Der Radverkehr kann durch sichere Fahrradwege attraktiver gemacht werden. Zusätzlich können die Fahrkartenpreise für den öffentlichen Personennahverkehr (ÖPNV) stark verbilligt bzw. kostenlos werden und Förderprämien für Lastenräder gewährt werden. ',
      },
      {
        headline: 'Carsharing fördern',
        text: 'Öffentlichen Parkraum für private PKW kann reduziert und Plätze für <a class="link" data-index="carsharing">Carsharing</a> reserviert werden. ',
      },
      {
        headline: 'Güterverkehr umbauen',
        text: 'Für den straßengebundenen Güterverkehr mit LKW können Maßnahmen ergriffen werden, die ihn im Vergleich zum schienengebundenen Güterverkehr verteuern. Gleichzeitig wird der Wechsel auf umweltfreundlichere Antriebe beschleunigt über finanzielle Entlastungen unterstützt. Die LKW-<a class="link" data-index="maut">Maut</a>  könnte schrittweise gesteigert werden. Sie ist abhängig von <a class="link" data-index="fahrleistung">Fahrleistung</a> (km) und <a class="link" data-index="schadstoffklasse">Schadstoffklasse</a>. Zusätzlich könnte die LKW-<a class="link" data-index="maut">Maut</a> auf alle Landes- und Kommunalstraßen ausgeweitet werden, um zu verhindern, dass LKW aufgrund der <a class="link" data-index="maut">Maut</a> auf Landes- und Kommunalstraßen ausweichen. Das Schienennetz wird so erweitert, dass noch längere Güterzüge darauf Platz und Fahrzeiten finden.'
      },
    ]
  },
  bottom: {
    buttonConfigurator: 'Test starten',
    buttonConfiguratorLink: 'configurator',
    buttonPathOverview: 'Direkt zu den Pfaden',
    buttonPathOverviewLink: 'path-overview',
    buttonBottomBackLink: '/onboarding',
    buttonBottomBack: 'Zum Anfang',
  }
}
