export default {
  text: 'Zentrales Merkmal dieses Politikpfades ist die weitreichende Umgestaltung des gesamten Verkehrssystems durch Änderungen im Mobilitätsverhalten, in der Stadtplanung und weiteren Infrastrukturen. Fahrzeuge mit fossilem Antrieb werden nicht nur durch Nullemissionsfahrzeuge ersetzt, sondern neue, geteilte Mobilitätsformen, ÖPNV und Fuß- und Radverkehr stehen im Mittelpunkt der Verkehrsplanung. ',
  title: 'Neue Mobilität',
  detailOverlay: {
    overline: 'Detailexploration',
    headline: 'Neue Mobilität',
    text: 'Was wäre wenn, … <br />79 Mio. Tonnen CO\u2082 müssen bis 2030 laut Klimaschutzgesetz eingespart werden (gegenüber 2019). Was passiert, wenn wir nicht handeln? ',
    label: 'Wie schwer eine Maßnahme wiegt, wenn man sie nicht ergreift, sehen sie hier.',
    items: [
      {
        iconId: 'trafficJam',
        label: '',
        text: 'Ohne die Maßnahmen für den ÖPNV und Radverkehr würden in diesem Pfad im Jahr 2030 bis zu 4,7 Mt. CO\u2082 mehr emittiert werden.',
        data: 4.7
      },
      {
        iconId: 'trafficJam',
        label: '',
        text: 'Ohne die Maßnahme der Reduzierung von PKW (Parkraumverknappung in Kombination mit City-Maut) aus Innenstädten würden in diesem Pfad im Jahr 2030 bis zu 3,5 Mt. CO\u2082 mehr ausgestoßen werden.',
        data: 3.5
      },
      {
        iconId: 'trafficJam',
        label: '',
        text: 'Ohne Neuzulassungsquote und Car-Sharing-Angebote würden in diesem Pfad im Jahr 2030 bis zu 3,5 Mt. CO\u2082 mehr ausgestoßen werden.',
        data: 3.5
      },
      {
        iconId: 'trafficJam',
        label: '',
        text: 'Würden in diesem Pfad innerdeutsche Flüge nicht verboten werden, so würden durch diesen Flugverkehr im Jahr 2030 insgesamt 1,5 Mt. mehr CO\u2082 ausgestoßen werden.',
        data: 1.5
      },
    ]
  },
  details: {
    title: 'Flottengrenzwerte',
    general: {
      title: 'Allgemein',
      text: 'Beziehen sich auf gemittelten Grenzwert für CO\u2082-Ausstoß über verkaufte <a class="link" data-index="flotte">Flotte</a> hinweg, den verkaufte Fahrzeuge nicht überschreiten dürfen <br /> <br /> Zurzeit Flottengrenzwertpfad EU-weit festgelegt: PKW 2030 -35% ggü. 2021, LKW 2030 -30% ggü. 2019 <br /> <br /> Optionen: Flottengrenzwerte könnten in Zukunft (a) auf einem Niveau eingefroren werden (um OEMs nicht weiter zu belasten oder weil Wirkung erreicht bzw. mit anderen Maßnahmen erreicht), (b)moderat (2030 -50%) oder (c) streng (2030 -X%) angehoben werden (um sie als Leitmaßnahmen zur Zielerreichung zu nutzen), (d) ein absolutes oberes Limit, also dann keinen Mittelwert mehr, für Neuzulassungen aufweisen (X g/km) oder (e) als Instrument auslaufen (weil z.B. andere Instrumente deren Effekt ersetzen) ',
    },
    path: {
      title: 'Im Pfad “Regulierung”',
      items: [
        {
          overline: 'Lastenverteilung über Einkommens-gruppen hinweg',
          text: 'Regressiv',
        },
        {
          overline: 'Ungleichheit innerhalb der Einkommensgruppe (Indiz für Härtefälle)',
          text: 'Niedrig',
        },
        {
          overline: 'Mechanismus',
          text: 'Obwohl ärmere Haushalte tendenziell kleinere und dadurch sparsamere Autos fahren, ist die Belastung über höhere PKW-Kosten durch die Grenzwert für sie relativ zum Einkommen höher.',
        },
        {
          overline: 'Größenordnung der Effekte',
          text: 'Groß <br /> Grobe Abschätzung (äquivalent zu Schatten-CO\u2082-Preis von 330€) <br /> <br /> ärmste Fünftel: ~4,2% Belastung des Haushaltseinkommens <br /> <br /> reichste Fünftel: ~2,1% Belastung des Haushaltseinkommens',
        },
      ]
    }
  },
  screws: {
    title: '',
    items: [
      {
        title: 'ÖPNV',
        iconId: '',
        text: 'Der ÖPNV ist 2030 kostenfrei. Die Preise für Fernbahn-Tickets werden leicht reduziert. Für die Bahn werden außerdem verstärkt <a class="link" data-index="sozialtickets">Sozialticket</a> angeboten und die BahnCard wird finanziell gefördert. Die Bundesmittel für den Ausbau des ÖPNVs und des Schienenverkehrs werden zusätzlich erhöht. ',
      },
      {
        title: 'PKW in Innenstädten',
        iconId: '',
        text: 'Der öffentliche Parkraum in Städten wird um 75% reduziert. Auf den verbleibenden öffentlichen Parkplätzen ist das Parken nur noch für Carsharing-Fahrzeuge erlaubt. Außerdem gibt es eine City-Maut, bei der ab 2030 jede Einfahrt in Innenstädte mit mehr als 100.000 Einwohner:innen 10€, ab 2045 15€ kostet. Somit werden PKW in Städten stark zurückgedrängt. ',
      },
      {
        title: 'Neuzulassungsquote & Carsharing',
        iconId: '',
        text: 'Durch eine geringere Pkw-Neuzulassungsquote (durch eine allgemein nachlassende Kaufbereitschaft, oder ggf. eine jährliche Obergrenze für Neuzulassungen) und vermehrte Carsharing-Angebote halbiert sich die Zahl der privat besessenen Pkw bis 2045 fast. Für den Pkw-Verkehr werden durch neue Navigations-Apps Routen, die minimale Umweltwirkungen haben, vorgeschlagen  und auch tatsächlich gefahren.',
      },
      {
        title: 'Veränderte Stadtplanung',
        iconId: '',
        text: 'Das Ziel einer veränderten Stadtplanung ist, dass in maximal 30 Minuten innerhalb einer Stadt alle wichtigen Alltagsstationen wie Einkaufsmöglichkeiten, Ärzte oder Freizeitaktivitäten ohne Auto erreichbar sind. Besonders Rad- und Fußverkehr bekommen mehr Raum. So müssen zum Beispiel alle mehrspurigen Straßen um eine Fahrspur reduziert werden, um mehr Rad- und Fußwege einzurichten, zu verbreitern oder Grünanlagen zu erweitern.',
      },
    ],
    button: 'Detailexploration',
  },
  description: {
    items: [
      {
        title: 'Entwicklung PKW ',
        iconId: '',
        items: [
          {
            title: 'PKW-Bestand',
            text: 'Sowohl die Gesamtanzahl der Pkw als auch die Zusammensetzung des Pkw-Bestands ändern sich stark. Der Bestand sinkt deutlich. Deshalb gibt es 2030  insgesamt auch weniger Elektro-Pkw als in den anderen Pfaden, 2045 sind sie aber die Mehrheit. Plug-in-Hybrid-Fahrzeuge spielen eine geringe Rolle. Aufgrund des hohen Energiebedarfs für Wasserstoff beginnt die Zukunft für Wasserstoff-Autos erst spät. Verbrenner gibt es 2045 nur noch wenige, diese werden aber emissionsfrei mit synthetischen Kraftstoffen betrieben.',
            dataVisual: {
              type: 'bar',
              maxData: 48000000,
              label: 'PKW: ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Gesamt', data: 47715977 },
                    { label: 'Verbrenner', data: 47473490 },
                    { label: 'Elektro-PKW', data: 138731 },
                    { label: 'Plug-In-Hybrid', data: 103756 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Gesamt', data: 40427441 },
                    { label: 'Verbrenner', data: 29306397 },
                    { label: 'Elektro-PKW', data: 7325027 },
                    { label: 'Plug-In-Hybrid', data: 3796016 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Gesamt', data: 28237484 },
                    { label: 'Verbrenner', data: 4843118 },
                    { label: 'Elektro-PKW', data: 18853822 },
                    { label: 'Plug-In-Hybrid', data: 4539445 },
                    { label: 'Wasserstoff PKW', data: 1099 },
                  ]
                },
              ]
            }
          },
          {
            title: 'Pkw-Besitz-Rate',
            text: 'Die Anzahl der PKW auf 1000 Einwohner sinkt aufgrund verbesserter Alternativen und Verhaltensänderungen stark. ',
            dataVisual: {
              type: 'circle',
              label: 'Pkw pro <br /> 1.000 Einwohner:innen',
              items: [
                {
                  label: 'Jetzt',
                  data: 574
                },
                {
                  label: '2030',
                  data: 484
                },
                {
                  label: '2045',
                  data: 342
                },
              ]
            }
          },
        ]
      },
      // {
      //   title: 'Wie entwickeln sich Rad-/Fußverkehr?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Fuß',
      //       text: '',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 36238286522 },
      //               { label: 'Stadt', data: 26498840336 },
      //               { label: 'Land', data: 10109019631 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 40460969550 },
      //               { label: 'Stadt', data: 29913084050 },
      //               { label: 'Land', data: 10547885500 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 41577674188 },
      //               { label: 'Stadt', data: 31571230438 },
      //               { label: 'Land', data: 10378678630 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Rad',
      //       text: 'Die Menschen gehen mehr zu Fuß, bis 2045 um ca. 10 % mehr, der große Anteil dieser Steigerung findet in Städten statt. <br />Der Radverkehr steigt auch, bis 2045 um fast 40 %. Wieder findet die größere Veränderung in der Stadt statt, aber auch auf dem Land legt der Radverkehr zu.',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 44488614277 },
      //               { label: 'Stadt', data: 30725698193 },
      //               { label: 'Land', data: 10114915450 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 50009213812 },
      //               { label: 'Stadt', data: 37879759423 },
      //               { label: 'Land', data: 12129454389 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 61392886642 },
      //               { label: 'Stadt', data: 49733843283 },
      //               { label: 'Land', data: 15453600970 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
      {
        title: 'Entwicklung öffentliche Verkehrsmittel',
        iconId: '',
        items: [
          {
            title: 'Nachfrage nach ÖPNV & Fernbahn',
            text: 'Die Nachfrage für ÖPNV in der Stadt und auf dem Land steigt bis 2030 um mehr als das Doppelte und erfährt danach bis 2045 nur noch leichte Steigerungen. Die Nachfrage nach Fernbahnreisen verdoppelt sich fast bis 2045',
            dataVisual: {
              type: 'bar',
              maxData: 250300000000,
              label: 'Personenkilometer',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Stadt', data: 100769895169 },
                    { label: 'Land', data: 21429048023 },
                    { label: 'Fernbahn', data: 40143546361 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Stadt', data: 246957276678 },
                    { label: 'Land', data: 54339783618 },
                    { label: 'Fernbahn', data: 63790934943 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Stadt', data: 260058512324 },
                    { label: 'Land', data: 56180383363 },
                    { label: 'Fernbahn', data: 75921409647 },
                  ]
                },
              ]
            }
          },
        ]
      },
      {
        title: 'Wer bewegt sich wie auf Stadt-/Landstrecken?',
        iconId: '',
        items: [
          {
            title: 'Verkehrsmix',
          },
          {
            title: 'Stadt',
            text: '',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 52 },
                    { label: 'Fuß', data: 25 },
                    { label: 'Rad', data: 13 },
                    { label: 'ÖPNV', data: 11 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 35 },
                    { label: 'Fuß', data: 28 },
                    { label: 'Rad', data: 14 },
                    { label: 'ÖPNV', data: 23 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Pkw', data: 28 },
                    { label: 'Fuß', data: 31 },
                    { label: 'Rad', data: 16 },
                    { label: 'ÖPNV', data: 25 },
                  ]
                },
              ]
            },
          },
          {
            title: 'Land',
            text: 'Stadtbewohner:innen reduzieren ihre Pkw-Nutzung sehr stark und steigen im Gegenzug auf Fuß, Rad und den ÖPNV um. </br> Auch Landbewohner:innen senken ihre Pkw-Nutzung. Auch hier steigt der Radverkehr und die ÖPNV-Nutzung, da es auch auf dem Land ein erweitertes und kostenfreies Angebot gibt.',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 63 },
                    { label: 'Fuß', data: 21 },
                    { label: 'Rad', data: 10 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 55 },
                    { label: 'Fuß', data: 23 },
                    { label: 'Rad', data: 12 },
                    { label: 'ÖPNV', data: 12 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Pkw', data: 51 },
                    { label: 'Fuß', data: 23 },
                    { label: 'Rad', data: 14 },
                    { label: 'ÖPNV', data: 13 },
                  ]
                },
              ]
            },
          }
        ]
      },
      // {
      //   title: 'Wieviel Stadt-/Landbewohner:innen haben eigene Autos?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Stadt',
      //       text: '',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 545
      //           },
      //           {
      //             label: '2030',
      //             data: 431
      //           },
      //           {
      //             label: '2045',
      //             data: 250
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Land',
      //       text: 'Die Anzahl der PKW in der Stadt reduziert sich bis 2045 um mehr als die Hälfte und auch auf dem Land gibt es einen starken Rückgang.',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 625
      //           },
      //           {
      //             label: '2030',
      //             data: 579
      //           },
      //           {
      //             label: '2045',
      //             data: 500
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
    ],
  },
  button: 'Zurück zur Pfadübersicht',
  buttonLink: '/path-overview'
}
