































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import imageTop from '../assets/images/impact.svg'

interface AccordionItem {
  title: string
  text: string
  isTextFirst?: boolean

  items: {
    text: string
    title: string
    dataVisual?: {
      type: string
      label: string
      maxData?: number
      minData?: number
      items: {
        colors?: string
        label: string
        data?: number
        items?: {
          label: string, data: number
        }[]
      }[]
    }
  }[]
}


@Component({
})
export default class Impact extends Vue {
  @Prop() data!: {
    title: string
    text: string
    accordion: {
      title: string
      items: AccordionItem[]
    }
    endText: string
    endLink: string
  }

  isOnboardingHide = false

  imageTop = imageTop

  $refs!: {
    text: HTMLElement
  }

  mounted() {
    const $glossarLinks = this.$refs.text.querySelectorAll('.link')

    $glossarLinks.forEach(($link) => {
      $link.addEventListener('click', () => {
        if ($link instanceof HTMLElement) {
          this.$router.push({ query: { glossar: $link.dataset.index || '' } })
        }
      })
    })
  }
}
