import Vue from 'vue'
import Icon from './components/Icon.vue'
import Button from './components/Button.vue'
import TextElement from './components/TextElement.vue'
import SplashScreen from './components/SplashScreen.vue'
import OnboardingAnimation from './components/OnboardingAnimation.vue'
import SwitchInput from './components/SwitchInput.vue'
import OnboardingPagination from './components/OnboardingPagination.vue'
import ProgressBar from './components/ProgressBar.vue'
import OverlayWebsite from './components/OverlayWebsite.vue'
import Accordion from './components/Accordion.vue'
import AppHeader from './components/AppHeader.vue'
import AnswerCard from './components/AnswerCard.vue'
import Overlay from './components/Overlay.vue'
import StackedBarChart from './components/StackedBarChart.vue'
import Tabs from './components/Tabs.vue'
import CheckList from './components/CheckList.vue'
import AdviceNavigation from './components/AdviceNavigation.vue'
import AdviceCard from './components/AdviceCard.vue'
import AdviceList from './components/AdviceList.vue'
import SuperButton from './components/SuperButton.vue'
import PathNavigation from './components/PathNavigation.vue'
import DetailOverlay from './components/DetailOverlay.vue'
import ConditionCard from './components/ConditionCard.vue'
import DataVisualBar from './components/DataVisualBar.vue'
import DataVisualCircle from './components/DataVisualCircle.vue'
import DataVisualStackedBar from './components/DataVisualStackedBar.vue'
import DataVisualBarMap from './components/DataVisualBarMap.vue'
import DataVisualStackedBarVertical from './components/DataVisualStackedBarVertical.vue'
import DataVisualBarTwo from './components/DataVisualBarTwo.vue'
import DataVisualBatteries from './components/DataVisualBatteries.vue'
import AdviceIllustration from './components/AdviceIllustration.vue'
import GlossarOverlay from './components/GlossarOverlay.vue'

Vue.component('Icon', Icon)
Vue.component('Button', Button)
Vue.component('TextElement', TextElement)
Vue.component('SplashScreen', SplashScreen)
Vue.component('OnboardingAnimation', OnboardingAnimation)
Vue.component('SwitchInput', SwitchInput)
Vue.component('OnboardingPagination', OnboardingPagination)
Vue.component('AppHeader', AppHeader)
Vue.component('Accordion', Accordion)
Vue.component('OverlayWebsite', OverlayWebsite)
Vue.component('ProgressBar', ProgressBar)
Vue.component('AnswerCard', AnswerCard)
Vue.component('Overlay', Overlay)
Vue.component('StackedBarChart', StackedBarChart)
Vue.component('Tabs', Tabs)
Vue.component('CheckList', CheckList)
Vue.component('AdviceNavigation', AdviceNavigation)
Vue.component('AdviceList', AdviceList)
Vue.component('AdviceCard', AdviceCard)
Vue.component('SuperButton', SuperButton)
Vue.component('PathNavigation', PathNavigation)
Vue.component('DetailOverlay', DetailOverlay)
Vue.component('ConditionCard', ConditionCard)
Vue.component('DataVisualBar', DataVisualBar)
Vue.component('DataVisualCircle', DataVisualCircle)
Vue.component('DataVisualStackedBar', DataVisualStackedBar)
Vue.component('DataVisualBarMap', DataVisualBarMap)
Vue.component('DataVisualStackedBarVertical', DataVisualStackedBarVertical)
Vue.component('DataVisualBatteries', DataVisualBatteries)
Vue.component('AdviceIllustration', AdviceIllustration)
Vue.component('GlossarOverlay', GlossarOverlay)
Vue.component('DataVisualBarTwo', DataVisualBarTwo)
