

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AdviceNavigation extends Vue {
  @Prop() headline!: string
  @Prop() prev!: string
  @Prop() next!: string
  @Prop({ default: true }) isInfoShown!: boolean
}
