








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OnboardingPagination extends Vue {
  @Prop({ required: true }) currentSlide!: number
}

