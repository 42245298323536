











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { STORAGE_KEY_HIDE_ONBOARDING } from '../fixtures/storage'
import { vwTOpx } from '../utils/convertor'

@Component({
})
export default class Onboarding extends Vue {
  @Prop() data!: {
    button: string
    items: {
      imageId: string,
      title: string
      text: string
      subline: string // if enable, enabled skip logic
    }[]
    isOnboardingHide: boolean
  }

  currentSlide = 1

  $refs!: {
    itemsOuter: HTMLElement
  }

  created(){
      this.data.isOnboardingHide = localStorage.getItem(STORAGE_KEY_HIDE_ONBOARDING) === 'true' || false
  }

  mounted(){

    window.addEventListener('resize', this.onResize)
  }


  setIsOnboardingHide(){
    localStorage.setItem(STORAGE_KEY_HIDE_ONBOARDING, String(this.data.isOnboardingHide))
  }

  onButton() {
    if (this.currentSlide === this.data.items.length) {
      this.$router.push({ name: 'Home' })

      return
    }

    this.$refs.itemsOuter.scrollLeft = this.$refs.itemsOuter.clientWidth*this.currentSlide
    this.currentSlide += 1
  }

  onResize() {
    this.$refs.itemsOuter.scrollLeft = this.$refs.itemsOuter.clientWidth*(this.currentSlide - 1 )
  }

  onClose() {
    this.$router.push({ name: 'Home' })
  }

}
