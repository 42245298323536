











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})
export default class Splash extends Vue {
  @Prop() data!: {
    title: string
    subtitle: string
    bottomLine: string
  }

  mounted() {
    setTimeout(() => {
      this.$router.push({ name: 'Start' })
    }, 1000)
  }

  onClick(): void {
    this.$router.push({ name: 'Start' })
  }
}
