var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"text",staticClass:"impact"},[_c('div',{staticClass:"grid-big"},[_c('TextElement',{attrs:{"headline":_vm.data.title,"text":_vm.data.text}})],1),_c('div',{staticClass:"grid-small image"},[_c(_vm.imageTop,{tag:"Component"})],1),_c('div',{staticClass:"grid-big"},[_c('TextElement',{attrs:{"headline":_vm.data.accordion.title}}),_c('Accordion',{attrs:{"items":_vm.data.accordion.items}},_vm._l((_vm.data.accordion.items),function(accordionItem,i){return _c('div',{key:i,attrs:{"slot":("accordion-" + i)},slot:("accordion-" + i)},_vm._l((accordionItem.items),function(dataItem,dataIndex){return _c('div',{key:dataIndex},[_c('div',{staticClass:"data-title"},[_vm._v(_vm._s(dataItem.title))]),_c('div',{staticClass:"data-text",domProps:{"innerHTML":_vm._s(dataItem.text)}}),(dataItem.dataVisual && dataItem.dataVisual.type === 'table')?_c('table',{staticClass:"data-text data-table"},[_c('tbody',_vm._l((dataItem.dataVisual.items),function(item,itemIndex){return _c('tr',{key:itemIndex},_vm._l((item.items),function(val,valIndex){return _c('td',{key:valIndex,style:({
                    background: item.colors ? item.colors[valIndex] : '',
                  }),domProps:{"innerHTML":_vm._s(val)}})}),0)}),0)]):_vm._e(),(dataItem.image)?_c('AdviceIllustration',{attrs:{"image":dataItem.image}}):_vm._e(),(
              dataItem.dataVisual && dataItem.dataVisual.type === 'bar-two'
            )?_c('DataVisualBarTwo',{attrs:{"label":dataItem.dataVisual.label,"items":dataItem.dataVisual.items,"maxData":dataItem.dataVisual.maxData,"minData":dataItem.dataVisual.minData}}):_vm._e(),(dataItem.dataVisual && dataItem.dataVisual.type === 'bar')?_c('DataVisualBar',{attrs:{"label":dataItem.dataVisual.label,"items":dataItem.dataVisual.items,"isTicksLabel":true,"maxData":dataItem.dataVisual.maxData}}):_vm._e(),(
              dataItem.dataVisual && dataItem.dataVisual.type === 'circle'
            )?_c('DataVisualCircle',{attrs:{"label":dataItem.dataVisual.label,"items":dataItem.dataVisual.items}}):_vm._e(),(
              dataItem.dataVisual &&
              dataItem.dataVisual.type === 'bar-stacked'
            )?_c('DataVisualStackedBar',{attrs:{"label":dataItem.dataVisual.label,"items":dataItem.dataVisual.items}}):_vm._e()],1)}),0)}),0),_c('router-link',{attrs:{"to":_vm.data.endLink}},[_c('SuperButton',{attrs:{"text":_vm.data.endText,"iconRight":"arrowRight"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }