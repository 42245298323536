import { render, staticRenderFns } from "./OnboardingPagination.vue?vue&type=template&id=45b23207&scoped=true&"
import script from "./OnboardingPagination.vue?vue&type=script&lang=ts&"
export * from "./OnboardingPagination.vue?vue&type=script&lang=ts&"
import style0 from "./OnboardingPagination.vue?vue&type=style&index=0&id=45b23207&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b23207",
  null
  
)

export default component.exports