export default {
  button: 'Weiter',
  items: [
    {
      imageId: 'mountain-road',
      title: 'Zukunftspfade',
      text: 'Mobilität bedeutet uns allen viel: Sie verknüpft Arbeit, Wohnen und Freizeit. Klar ist aber, dass wir Mobilität neu denken müssen, wenn wir Emissionen verringern und die Klimaschutzziele erreichen wollen. Doch wie kann die Mobilität der Zukunft aussehen? <br /> <br /> Das Ariadne-Forschungsteam hat Ihnen vier mögliche Pfade zusammengestellt, wie wir diese Zukunft beschreiten können.',
      subline: '',
    },
    {
      imageId: 'woman-config',
      title: 'Stellschrauben',
      text: 'Die vier Zukunftspfade sind durch den jeweiligen Einsatz von Stellschrauben definiert. Stellschrauben bezeichnen verschiedene Politikinstrumente und deren Ausgestaltung. Das können Kaufprämien für Elektroautos oder Neuzulassungsverbote für Verbrenner sein. Ein Pfad ist durch die jeweiligen Stellschrauben charakterisiert und durch die Art und Weise, wie an ihnen gedreht wird.',
      subline: '',
    },
    {
      imageId: 'city-view',
      title: 'Auswirkungen',
      text: 'Jedes Drehen an einer Stellschraube zieht Konsequenzen nach sich. Manche Auswirkungen sind eindeutig positiv, wie mehr Klimaschutz. Andere Auswirkungen wie Kosten für verschiedene Bevölkerungsgruppen sind schwerer zu beurteilen. <br />Welche Auswirkungen sind wir bereit mitzutragen? Wir möchten mit Ihnen diskutieren, an welchen Stellschrauben Sie wie stark drehen würden, um die Verkehrswende zu gestalten. ',
      subline: 'Beim nächsten mal nicht mehr anzeigen',
    },
  ]
}
