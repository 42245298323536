






import { Component, Prop, Vue } from 'vue-property-decorator'
import imageManScooter from '../assets/images/man-scooter.svg'
import imageCityView from '../assets/images/city-view.svg'
import imageWomanGlobe from '../assets/images/woman-globe.svg'
import imageMountainRoad from '../assets/images/mountain-road.svg'
import imageWomanConfig from '../assets/images/woman-config.svg'
import imageWomanConfigEmpty from '../assets/images/woman-config-empty.svg'
import imageWomanForrest from '../assets/images/woman-forrest.svg'
import imageWindmills from '../assets/images/windmills.svg'
import imamgePlant from '../assets/images/plant.svg'

enum ImageId {
  WOMAN_GLOBE = 'woman-globe',
  MAN_SCOOTER = 'man-scooter',
  CITY_VIEW = 'city-view',
  MOUNTAIN_ROAD = 'mountain-road',
  WOMAN_CONFIG_EMPTY = 'woman-config-empty',
  WOMAN_FORREST = 'woman-forrest',
  WOMAN_CONFIG = 'woman-config',
  WINDMILLES = 'windmills',
  PLANT = 'plant',
}

// The images changed over time so the names are not always correct
const imageMap = {
  [ImageId.MAN_SCOOTER]: imageManScooter,
  [ImageId.WOMAN_GLOBE]: imageWomanGlobe,
  [ImageId.CITY_VIEW]: imageCityView,
  [ImageId.MOUNTAIN_ROAD]: imageMountainRoad,
  [ImageId.WOMAN_CONFIG_EMPTY]: imageWomanConfigEmpty,
  [ImageId.WOMAN_CONFIG]: imageWomanConfig,
  [ImageId.WOMAN_FORREST]: imageWomanForrest,
  [ImageId.WINDMILLES]: imageWindmills,
  [ImageId.PLANT]: imamgePlant,
}

@Component
export default class OnboardingAnimation extends Vue {
  @Prop() imageId!: ImageId

  get image(): string {
    return imageMap[this.imageId]
  }
}

