









































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DataVisualBatteries extends Vue {
  @Prop() items!: {
    label: string
    items: {
      label: string, data: number
    }[]
  }[]
  @Prop() maxData!: number

  tabActiveIndex = 0

  colors: string[] = [
    'blue',
    'purple',
    'pink',
  ]

  get allItemData(): number[] {
    const dataItems: number[] = []

    this.items.forEach((item) => {
      dataItems.push(...item.items.map((child) => child.data))
    })

    return dataItems
  }

  get itemHeights(): number[] {
    const MAX_HEIGHT = 200
    const MIN_HEIGHT = 20

    return this.currentItem.items.map((item) => {
      const perc = item.data / this.maxData

      return ((MAX_HEIGHT - MIN_HEIGHT) * perc) + MIN_HEIGHT
    })
  }

  get tabs(): string[] {
    return this.items.map((l) => l.label)
  }

  get currentItem() {
    return this.items[this.tabActiveIndex]
  }
}
