














import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import headerData from './data/header'
import glossarData from './data/glossar'
import { STORAGE_KEY_HIDE_ONBOARDING } from '../../fixtures/storage'

const TRANSITION_DEFAULT = 'fade'
const PAGES_NO_HEADER = [
  'Splash',
  'Onboarding',
  'Start'
]

@Component
export default class App extends Vue {
  transitionName = TRANSITION_DEFAULT

  headerData = headerData

  isGlossarOpen = false

  currentGlossarIndex = "-1"

  glossarItems = glossarData.items

  get isHeaderShown(): boolean {
    return !!this.$route.name && !PAGES_NO_HEADER.includes(this.$route.name.toString())
  }

  @Watch('$route')
  onRoute(to: Route, from: Route) {
    if (to.query.glossar && (to.query.glossar !== from.query.from)) {
      this.isGlossarOpen = true
      this.currentGlossarIndex = to.query.glossar.toString()
    }

    if (to.path=='/home' && ('/onboarding' !== from.path && '/home' !== from.path)) {
        const hideOnboarding: boolean = localStorage.getItem(STORAGE_KEY_HIDE_ONBOARDING) === 'true'
        if(!hideOnboarding){
            this.$router.push('/onbaording')
        }
    }

    if (to.meta?.routeTransition) {
      this.transitionName = to.meta?.routeTransition || TRANSITION_DEFAULT
    } else {
      this.transitionName = TRANSITION_DEFAULT
    }
  }
}
