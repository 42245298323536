






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop() current!: number
  @Prop() total!: number

  get currentPercentage(): number {
    return (this.current / (this.total + 1)) * 100
  }
}
