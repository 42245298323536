


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import imageTown from '../assets/images/town.svg'
import { STORAGE_KEY_TRAFFIC_CHECKLIST } from '@/fixtures/storage'

@Component({
})
export default class HomeTraffic extends Vue {
  @Prop() data!: {
    intro: {
      text: string
    }
    checkList: {
      overline: string
      headline: string
      text: string
      overlayHeadline: string
      buttonClose: string
      buttonGlossar: string
      items: {
        headline: string
        text: string
      }[]
    }
    bottom: {
      buttonConfigurator: string
      buttonConfiguratorLink: string
      buttonPathOverview: string
      buttonPathOverviewLink: string
      buttonBottomBackLink: '/onboarding',
      buttonBottomBack: 'Zum Anfang',
    }
  }

  imageTown = imageTown

  isOverlayOpen = false
  currentItemIndex = -1

  itemsCheckedMap: { [index: number]: boolean } = {}

  $refs!: {
    text: HTMLElement
  }

  @Watch('isOverlayOpen')
  watchOverlay(isOpen: boolean) {
    setTimeout(() => {
      if (isOpen === false) return

      const $glossarLinks = this.$refs.text.querySelectorAll('.link')

      $glossarLinks.forEach(($link) => {
        $link.addEventListener('click', () => {
          if ($link instanceof HTMLElement) {
            this.$router.push({ query: { glossar: $link.dataset.index || '' } })
          }
        })
      })
    })
  }

  mounted() {
    const data = localStorage.getItem(STORAGE_KEY_TRAFFIC_CHECKLIST)

    if (data) {
      this.itemsCheckedMap = JSON.parse(data)
    }

  }

  get currrentItem() {
    return this.data.checkList.items[this.currentItemIndex]
  }

  get checkListItems() {
    return this.data.checkList.items.map((item, index) => ({
      isChecked: this.itemsCheckedMap[index],
      headline: item.headline,
    }))
  }

  onChecklistItem(index: number) {
    this.currentItemIndex = index
    this.isOverlayOpen = true
    this.$set(this.itemsCheckedMap, index, true)
    localStorage.setItem(STORAGE_KEY_TRAFFIC_CHECKLIST, JSON.stringify(this.itemsCheckedMap))
  }
}
