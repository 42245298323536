























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CheckList extends Vue {
  @Prop() items!: {
    isChecked: boolean
    headline: string
  }[]
}
