







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component
export default class DataVisualBar extends Vue {
  @Prop() isTicksLabel!: boolean
  @Prop() maxData!: number
  @Prop() label!: string
  @Prop() items!: {
    label: string
    items: {
      label: string, data: number
    }[]
  }[]

  tabActiveIndex = 0
  chart: Chart | null = null

  get tabs(): string[] {
    return this.items.map((l) => l.label)
  }

  get dataSets() {
    return this.items.map((item) => item.items)
  }

  get dataSetCurrent() {
    return this.dataSets[this.tabActiveIndex]
  }

  $refs!: {
    chart: HTMLCanvasElement
  }

  @Watch('tabActiveIndex')
  watchIndex() {
    this.chart?.data.datasets.forEach((dataset) => {
      dataset.data = this.dataSetCurrent.map((i) => i.data)
    })

    this.chart?.update()
  }

  getAxisConversionFactorAndLabel(val) {
    if (typeof val === 'string') {
      return [1, '']
    }

    const TSD = 1000
    const MIL = 1000000
    const BIL = 1000000000

    if (val > BIL) {
      return [BIL, 'Mrd.']
    }

    if (val > MIL) {
      return [MIL, 'Mio.']
    }

    if (val > TSD) {
      return [TSD, 'Tsd.']
    }

    return [1, '']

  }

  mounted() {
    this.chart = new Chart(this.$refs.chart, {
      type: 'bar',
      data: {
        labels: this.dataSetCurrent.map((i) => i.label),
        datasets: [{
          label: this.label,
          data: this.dataSetCurrent.map((i) => i.data),
          backgroundColor: '#91AFCD',
          hoverBackgroundColor: '#2A639D',
          borderRadius: 2,
          maxBarThickness: 24,
        }]
      },
      plugins:[ChartDataLabels],
      options: {
        plugins: {
          tooltip: {
            enabled: false,
            backgroundColor: '#fff',
            titleColor: '#343A40',
            bodyColor: '#343A40',
            borderColor: '#91AFCD',
            borderWidth: 2,
            callbacks: {
              title: () => '',
              label: (data) => {
                if (typeof data.raw === 'string') {
                    return data.formattedValue
                }
                const value = parseFloat(data.raw)
                const [conversionFactor, conversionLabel] = this.getAxisConversionFactorAndLabel(value)
                if (conversionFactor===1){
                    return parseInt(value)
                }
                return `${((value/conversionFactor).toFixed(1)).toString().replace('.', ',')} ${conversionLabel}`.trim()
              }
            },
          },
          legend: {
            display: false
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value) => {
                if (typeof value === 'string') {
                    return value
                }
                value = parseFloat(value)
                const [conversionfactor, conversionlabel] = this.getAxisConversionFactorAndLabel(value)
                if (conversionfactor===1){
                    return parseInt(value)
                }
                return `${((value/conversionfactor).toFixed(1)).toString().replace('.', ',')} ${conversionlabel}`.trim()
            }
          },
        },
        scales: {
          y: {
            title: {
                display: this.label!=='',
                text: this.label,
            },
            suggestedMax: this.maxData,
            grid: { display: false, },
            beginAtZero: true,
            ticks: {
              count: 5,
              callback: (val) => {
                if (typeof val === 'string') {
                  return val
                }

                const MIL = 1000000
                const BIL = 1000000000

                if (val > BIL) {
                  return `${val / BIL} Mrd.`
                }

                if (val > MIL) {
                  return `${val / MIL} Mio.`
                }
                return val
                //return this.isTicksLabel ? `${val} ${this.label}` : val
              },
            }
          },
          x: {
            grid: { display: false, },
            ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
          },
        }
      }
    })
  }
}
