export default {
  items: [
    {
      id: 'automatisierung-des-verkehrs-autonomer-verkehr',
      title: 'Automatisierung des Verkehrs/ autonomer Verkehr',
      text: 'Automatisierung des Verkehrs bezieht sich auf technische Entwicklungen, die Fahrzeuge dank Fahrerassistenzsysteme teilweise oder vollständig ohne Fahrer, also autonom fahren lassen. Denkbar sind zum Beispiel autonom fahrende Busse, die die letzte Meile vom Regionalbahnhof zur Haustür abdecken.',
    },
    {
      id: 'bonus-malus-system',
      title: 'Bonus-Malus-System',
      text: 'Anreizsystem bei der Neuzulassung von Fahrzeugen: Beim Kauf eines Neufahrzeugs mit niedrigen CO\u2082-Emissionswerten erhalten Käufer:innen eine Bonuszahlung, während Fahrzeuge, die viel CO\u2082 ausstoßen, beim Kauf durch eine Malus-Zahlung teurer werden.',
    },
    {
      id: 'carsharing',
      title: 'Carsharing',
      text: 'Carsharing bedeutet, dass Menschen Autos für einzelne Fahrten mieten können, die in Stadt und Gemeindegebieten verteilt sind. Durch Carsharing kann die Anzahl der Pkw und Parkplätze in diesen Gebieten reduziert werden. Denn private Pkw stehen im Durchschnitt 23 Stunden am Tag. Die Kosten für Carsharing sind circa ¼ bis ½ einer normalen Taxifahrt. Nutzer:innen sparen sich im Vergleich zum privaten Pkw Kosten wie Steuern, Versicherung, Reparaturen und Parken.',
    },
    {
      id: 'co2-kohlenstoffdioxid',
      title: 'CO\u2082 / Kohlenstoffdioxid',
      text: 'Klimaschädliches Gas',
    },
    {
      id: 'co2-emissionen',
      title: 'CO\u2082-Emissionen (auch: Kohlenstoff(dioxid)emissionen)',
      text: 'CO\u2082- oder Kohlenstoffdioxidemissionen entstehen durch die Verbrennung kohlenstoffhaltiger Materialen, wie Kohle, Benzin, Erdgas oder Flüssiggas. Sie tragen wesentlich zum Treibhausgaseffekt und somit zur globalen Klimaerwärmung bei. Es gibt weitere Treibhausgase, CO\u2082-Emissionen bilden jedoch den größten Anteil. Bei Biomasseverbrennung hängt die Klimawirkung von der Nachhaltigkeit der Nutzung ab.',
    },
    {
      id: 'co2-preis',
      title: 'CO\u2082-Preis',
      text: 'Der CO\u2082-Preis, auch Kohlenstoffpreis genannt, ist ein Preis, der pro ausgestoßener Tonne CO\u2082 erhoben wird. Er gehört zu den sogenannten marktwirtschaftlichen Klimapolitikinstrumenten. Der CO\u2082-Preis macht fossile Energieträger teurer. Davon profitieren erneuerbare Energien im Wettbewerb mit der fossilen Energie ebenso wie Innovationen, die beispielsweise die Energieeffizienz steigern, aber noch zu kostenintensiv sind. Der CO\u2082-Preis kann über eine Steuer eingeführt werden oder über ein Emissionshandelssystem. Zentral ist bei beiden Instrumenten, dass der Preis hoch genug sein muss, um eine Lenkungswirkung weg von den fossilen Energieträgern zu entfalten. Rückverteilung: Außerdem ist wichtig, wie mit den zusätzlichen Einnahmen des Staates umgegangen wird. Entweder werden dafür andere Steuern (bspw. Die Stromsteuer) gesenkt oder die Einnahmen aus dem CO\u2082-Preis werden an die Bürger:innen zurückgegeben, etwa durch eine direkte Rückzahlung. ',
    },
    {
      id: 'dekarbonisierte-kraftstoffe',
      title: 'Dekarbonisierte Kraftstoffe (auch alternative Kraftstoffe)',
      text: 'Alternative oder dekarbonisierte Kraftstoffe sind entweder ""biogen"" oder ""synthetisch"". Biogene Kraftstoffe werden aus Pflanzen, Pflanzenresten und -abfällen oder Gülle gewonnen, während synthetische Kraftstoffe (E-Fuels) mit erneuerbarem Strom aus Wasser und CO\u2082 erzeugt werden. Beide haben gemeinsam, dass sie bei der Verbrennung nicht mehr CO\u2082 freisetzen als bei der Herstellung der Kraftstoffe gebunden wird, und somit netto keine Emissionen verursachen.',
    },
    {
      id: 'deliberation',
      title: 'Deliberation',
      text: 'Deliberation, von lat. „deliberare“ zu dt. „abwägen“, „beschreibt eine auf den Austausch von Argumenten angelegte Form der Entscheidungsfindung unter Gleichberechtigten. Das bessere Argument und nicht die Mehrheitsabstimmung soll die Entscheidungen prägen und zu besseren Entschlüssen führen, weil – im Idealfall – alle Argumente gegeneinander abgewogen werden und eine Einigung auf die »beste« Lösung möglich ist“. Das Modell der Deliberation beruht auf den Gedanken des Philosophen Jürgen Habermas.',
    },
    {
      id: 'dieselprivileg',
      title: 'Dieselprivileg',
      text: 'Bislang wird für Diesel eine geringere Energiesteuer fällig als für Benzin (47,04 ct/l vs. 65,45 ct/l). Der Hintergrund ist, dass Diesel früher vor allem im Waren- und Güterverkehr eingesetzt wurde. Hohe Dieselkosten bedeuteten also hohe Herstellungskosten. Aus heutiger, ökologischer Sicht wird so aber ein falsches Preissignal gesetzt, zumal Diesel pro Liter mehr CO\u2082 als Benzin ausstößt. Da Personen aus reicheren Haushalten mehr Pkw fahren, profitieren sie vom Dieselprivileg überproportional.',
    },
    {
      id: 'deutschlandtakt',
      title: 'Deutschlandtakt',
      text: 'Der Deutschlandtakt bezeichnet die deutschlandweite Abstimmung der Fahrpläne aufeinander. Die größten deutschen Städte werden mit Personenverkehrszügen miteinander verbunden, die alle 30 Minuten abfahren. Der Regional- und Nahverkehr wird auf diesen Takt abgestimmt. Der Ausbau der Schieneninfrastruktur folgt dieser Fahrplananpassung.',
    },
    {
      id: 'emissionshandelssystem',
      title: 'Emissionshandelssystem',
      text: 'Der Emissionshandel ist der Handel mit Emissionsrechten (einer Art von Verschmutzungsrechten), die durch Emissionszertifikate verbrieft sind. Es geht hierbei um Emissionen von schädlichen Stoffen. Die größte Bedeutung hat heute der Handel von Emissionszertifikaten für Kohlendioxid (CO\u2082).',
    },
    {
      id: 'emission',
      title: 'Emission',
      text: 'Emission bedeutet „Ausstoß“ und Emissionen sind im umwelttechnischen Sinne Luftverunreinigungen, Geräusche, Erschütterungen, Licht, Wärme, Strahlen und ähnliche Umwelteinwirkungen. Häufig sind dabei Treibhausgase wie CO\u2082, Methan oder Stickoxide gemeint.',
    },
    {
      id: 'emittent',
      title: 'Emittent',
      text: 'Als Emittent wird die Quelle von Emissionen bezeichnet.'
    },
    {
      id: 'e-fuels',
      title: 'E-Fuels',
      text: 'Als E-Fuels (Abkürzung von englisch „electrofuels“, Elektro-Kraftstoffe) werden synthetische Kraftstoffe bezeichnet, die mittels Strom aus Wasser und Kohlenstoffdioxid (CO\u2082) hergestellt werden. Dieser Prozess ist sehr energieaufwändig und wird als Power-to-Fuel bezeichnet. Wenn der Strom vollständig aus erneuerbaren Quellen stammt und das CO\u2082 der Atmosphäre entnommen wird bzw. aus Biomasse oder Industrieabgasen stammt, können mittels E-Fuels Verbrennungsmotoren klimaneutral betrieben werden.',
    },
    {
      id: 'endenergieverbrauch',
      title: 'Endenergieverbrauch',
      text: 'Man bezeichnet als Endenergie die Energie, die bei Verbraucher:innen ankommt, etwa in Form von Brennstoffen und Kraftstoffen oder elektrischer Energie. Der Endenergieverbrauch in Deutschland ist seit Beginn der 1990er Jahre kaum gesunken. Im langjährigen Trend ist nur der Wärmeverbrauch rückläufig, während der Verbrauch von Kraftstoff und Strom nahezu konstant ist. Im Verkehr und in den privaten Haushalten ist der Energieverbrauch jeweils gestiegen.',
    },
    {
      id: 'energiesteuer',
      title: 'Energiesteuer',
      text: 'Eine Energiesteuer ist eine Steuer, die ein Staat auf Energieträger erhebt, beispielsweise auf Kraftstoffe, Heizöl, elektrische Energie oder Kernbrennstoffe. Energiesteuern dienen nicht nur der Finanzierung des Staates, sondern haben gleichzeitig auch eine erwünschte Lenkungswirkung: Da sie Energieträger verteuern, setzen sie einen Anreiz für die Verminderung des Energieverbrauchs.',
    },
    {
      id: 'erneuerbare-energien-erneuerbarer-strom-erneuerbare-quellen',
      title: 'Erneuerbare Energien/erneuerbarer Strom/erneuerbare Quellen',
      text: 'Unter erneuerbaren Energien, auch regenerative Energien genannt, versteht man Energieträger oder -quellen, die unendlich zur Verfügung stehen beziehungsweise in kürzerer Zeit wieder nachwachsen können – im Gegenteil zu fossilen Energieträgern wie Kohle oder Erdgas. Zu erneuerbaren Energieträgern zählen Wasserkraft, Solar- und Windenergie, Biomasse sowie Geothermie. Erneuerbarer Strom wird aus erneuerbaren Energieträgern hergestellt.',
    },
    {
      id: 'fahrleistung',
      title: 'Fahrleistung',
      text: 'Anzahl gefahrener Kilometern, üblicherweise in Fahrzeugkilometern (siehe unten)',
    },
    {
      id: 'fahrzeugkilometer',
      title: 'Fahrzeugkilometer',
      text: 'Als Fahrzeugkilometer (Fz-km) oder Wagenkilometer wird eine Maßgröße der Fahrleistung bezeichnet. Ein Fahrzeugkilometer entspricht der Bewegung eines einzelnen Fahrzeugs über eine Entfernung von einem Kilometer. Fahrzeugkilometer werden in der Verkehrsstatistik verwendet, um die Intensität der Verkehrswegenutzung und die Höhe der erbrachten Verkehrsleistung zu bestimmen. Meist wird ein Fahrzeugkilometer auf ein spezielles Verkehrsmittel bezogen, so etwa bei Zugkilometer, Buskilometer oder Kfz-Kilometer.',
    },
    {
      id: 'flotte',
      title: 'Flotte',
      text: 'Gesamtheit aller Fahrzeuge einer organisatorischen Einheit (z.B. Unternehmen, Staat)',
    },
    {
      id: 'flottengrenzwert',
      title: 'Flottengrenzwert',
      text: 'Flottengrenzwert bedeutet, dass der Durchschnitt aller von einem Hersteller in der EU in einem Jahr zugelassenen Fahrzeuge einen bestimmten Wert an CO\u2082-Emissionen pro Kilometer nicht überschreiten soll. Das heißt, Fahrzeuge mit geringerem oder gar keinem CO\u2082-Ausstoß können Fahrzeuge mit höherem Verbrauch ausgleichen. Nicht jedes einzelne neue Auto muss also diesen Flottengrenzwert einhalten. Die Autohersteller sind dann in der Pflicht, ihr Angebot auf einen attraktiven Mix auszurichten, der diese Werte einhält. In der EU gibt es diese Flottengrenzwerte seit 2012. Sie sollen bis 2030 immer weiter sinken. Beispiel: Durch eine Senkung der Flottengrenzwerte um 55% im Jahr 2030, wie durch die EU vorgeschrieben, müsste der durchschnittliche Wert der deutschen Flotte auf ungefähr 60g CO\u2082/km gesenkt werden. Zum Vergleich: ein mittlerer SUV stößt aktuell ca. 120-180g CO\u2082/km aus.',
    },
    {
      id: 'flottenmittel',
      title: 'Flottenmittel',
      text: 'Mittelwert des Ausstoßes von CO\u2082 in g/km einer Flotte',
    },
    {
      id: 'fossile-energieträger',
      title: 'Fossile Energieträger',
      text: 'Fossile Energieträger sind solche Energieträger, die vor langer Zeit beim Abbau von toten Pflanzen und Tieren entstanden sind. Hierzu gehören insbesondere Brennstoffe wie Kohle, Erdöl und Erdgas (auch Schiefergas), aber auch Torf. Alle fossilen Energieträger sind mehr oder weniger kohlenstoffhaltig (am meisten Kohle, am wenigsten Erdgas), so dass bei ihrer Verbrennung Kohlendioxid (CO\u2082) freigesetzt wird. Da diese CO\u2082-Emissionen bisher fast immer in die Atmosphäre entlassen werden, steigt der CO\u2082-Gehalt der Atmosphäre Jahr für Jahr, und dies wird zunehmende Klimaveränderungen zur Folge haben.',
    },
    {
      id: 'fossile-kraftstoffe',
      title: 'Fossile Kraftstoffe',
      text: 'Kraftstoffe sind meist flüssige oder gasförmige brennbare Stoffe, die zum Antrieb von Kraftmaschinen dienen, insbesondere von Verbrennungsmotoren. Sie werden in großem Umfang im Verkehr benötigt, aber auch für die Erzeugung elektrischer Energie in stationären Anlagen, z. B. in Blockheizkraftwerken. Fossile Kraftstoffe werden aus fossilen Energieträgern wie z.B. Erdöl hergestellt.',
    },
    {
      id: 'innerdeutsche-fluege',
      title: 'Innerdeutsche Flüge',
      text: 'Das bezeichnet grundsätzlich alle Linien-Flugverbindungen mit gleichzeitigem Start und Ziel auf dem Bundesgebiet. Zur Information: Oft diskutiert wird dabei die Ausnahme von Zubringerflügen zu Interkontinentalflügen.',
    },
    {
      id: 'kaufprämien',
      title: 'Kaufprämien',
      text: 'Eine Prämie ist ein finanzieller oder nicht monetärer („geldlicher“) Anreiz, der geschaffen wird, um ein gewünschtes Verhalten zu belohnen. Die Kaufprämie bezieht sich im Verkehrssektor in Deutschland auf staatliche Zuschüsse beim Kauf von Elektroautos mit dem Ziel, die schnelle Verbreitung elektrisch betriebener Fahrzeuge zu unterstützen (Marktaktivierung). Sie wird auch Umweltbonus genannt.',
    },
    {
      id: 'klimageld',
      title: 'Klimageld',
      text: 'Als Klimageld (oder auch Klimadividende, Energiegeld) Klimaprämie wird die pro-Kopf Rückvergütung (eines Teils der) Einnahmen aus der CO\u2082-Bepreisung an die Bürger*innen bezeichnet. ',
    },
    {
      id: 'klimaneutralität',
      title: 'Klimaneutralität',
      text: 'Klimaneutralität bedeutet, ein Gleichgewicht zwischen Kohlenstoffemissionen und der Aufnahme von Kohlenstoff aus der Atmosphäre in sogenannten Kohlenstoffsenken herzustellen. Um das zu erreichen, müssen alle Treibhausgasemissionen weltweit durch Kohlenstoffbindung in Senken ausgeglichen werden. Wichtig ist dies insbesondere bei nicht vermeidbaren Emissionen, wie z.B. in der Landwirtschaft oder der Zementindustrie. Natürliche Kohlenstoffsenken sind zum Beispiel Böden, Wälder oder Ozeane. Darüber hinaus gibt es auch künstliche Wege, Kohlenstoff zu binden, beispielsweise in Baumaterialien oder durch das Einlagern von CO\u2082 aus Biomasse oder Luftabscheidung unter der Erde in alte Erdgaslagerstätten (abgekürzt CCS). Laut Schätzungen entfernen natürliche Senken zwischen 9,5 und 11 Gigatonnen CO\u2082 pro Jahr. 2019 betrugen die jährlichen globalen CO\u2082-Emissionen 38 Gigatonnen.',
    },
    {
      id: 'klimaschutzziele',
      title: 'Klimaschutzziele',
      text: 'Klimaschutzziele bezeichnen vor allem Ziele für die Reduktion von Treibhausgasemissionen. Im Juni 2021 hat der Deutsche Bundestag stärkere Klimaschutzziele beschlossen und dafür das neue Klimaschutzgesetz 2021verabschiedet. Darin verankert wird das Ziel, bis 2045 klimaneutral zu sein – also nicht mehr CO\u2082 auszustoßen als wieder über Wälder oder andere Wege absorbiert werden kann. Bis 2030 sollen dafür insgesamt 65 Prozent aller Treibhausgas-Emissionen eingespart werden (im Vergleich zu 1990), das Klimaschutzziel 2040 sind dann 88 Prozent. Übergeordnet gibt es außerdem europäische Klimaziele. Über den Emissionshandel und nationale Emissionsminderungsziele wird dabei ein Ausgleich zwischen den Ländern möglich.',
    },
    {
      id: 'kobalt',
      title: 'Kobalt',
      text: 'Kobalt ist Teil des Kathodenmaterials von gängigen Batterien in Elektrofahrzeugen. Die Demokratischen Republik Kongo verfügt über die weltweit größten Kobaltvorkommen (ca. 3,5 Millionen Tonnen). In der Kritik stehen die Abbaubedingungen in nicht zugelassenen Kobaltminen im Kongo, aus denen etwa 10 % der weltweiten Nachfrage gedeckt werden. Nach Hochrechnungen könnten die Kobaltreserven bei einem unveränderten Fokus auf die gängigen Kathodenmaterialen an ihre Grenzen geraten. China ist der global wichtigste Produzent von Kobaltchemikalien. Zugleich ist China weltgrößter Absatzmarkt für die Elektromobilität und ein Schlüsselland für den Ausbau des Kobaltrecyclings.',
    },
    {
      id: 'ladeinfrastruktur',
      title: 'Ladeinfrastruktur',
      text: 'Meint die Verteilung und Verfügbarkeit von Ladestationen für Elektrofahrzeugen, vornehmlich außerhalb des eigenen Grundstücks, also öffentlich zugänglich.',
    },
    {
      id: 'lithium',
      title: 'Lithium',
      text: 'Lithium ist Teil des Kathodenmaterials in den aktuell verwendeten Batteriefabrikaten. Im Jahr 2020 war Australien der weltweit größte Lithiumproduzent. Die größten Lithiumvorkommen befinden sich in Chile (9.2 Mio. Tonnen) und Australien (2.8 Mio. Tonnen). Im Gegensatz zu Kobalt sind die heute identifizierten Lithiumreserven ausreichend, je nachdem wie sich die Nachfrage auch in Entwicklungs- und Schwellenländern entwickelt. Die Produktion müsste jedoch stark hochskaliert werden. Es ist aber unklar, ob der Abbau entsprechend hochgefahren werden kann, auch angesichts der Konflikte in der Atacamawüste in Chile und der angespannten politischen Lage im Land. Die Verfügbarkeit von Lithium kann ein limitierender Faktor beim Ausbau der E-Mobilität darstellen.',
    },
    {
      id: 'nullemissionsfahrzeug',
      title: 'Nullemissionsfahrzeug',
      text: 'Elektrofahrzeuge (batterieelektrisch) und Wasserstofffahrzeuge (Brennstoffzellenantrieb), die im Fahrbetrieb keine Abgasemissionen (Klimagase) ausstoßen.',
    },
    {
      id: 'maut',
      title: 'Maut',
      text: 'Eine Maut ist eine Gebühr für die Nutzung von Straßen. Im Moment ist die LKW-Maut in Deutschland auf Bundesfernstraßen zu entrichten und ist abhängig von Fahrleistung und Schadstoffklasse des LKW. Eine fahrleistungsabhängige Maut fällt pro gefahrenem Kilometer an und erhöht somit die Kosten einen zurückgelegten Weg. In Bezug auf die Verkehrswende wäre bspw. auch eine Maut für Autos denkbar.',
    },
    {
      id: 'parkraumbewirtschaftung',
      title: 'Parkraumbewirtschaftung',
      text: 'Parkraumbewirtschaftung meint das Erheben einer Gebühr für die Nutzung von Parkplätzen im öffentlichen Raum, zum Beispiel am Straßenrand.',
    },
    {
      id: 'pendlerpauschale',
      title: 'Pendlerpauschale',
      text: 'Die Pendlerpauschale bzw. Entfernungspauschale ist ein Betrag, der pro Kilometer Arbeitsweg von der Steuer abgesetzt werden kann. Pro Kilometer können derzeit 0,30 EUR geltend gemacht werden, unabhängig davon, mit welchem Verkehrsmittel der Weg zurückgelegt wurde. Bei Nutzung des ÖPNV statt des Autos können auch die Tages-, Wochen- oder Jahrestickets abgesetzt werden.',
    },
    {
      id: 'personenkilometer',
      title: 'Personenkilometer',
      text: 'Die Einheit Personenkilometer [Pkm] beschreibt die im Rahmen einer Personenbeförderung erbrachte Verkehrsarbeit. Diese definiert sich als Produkt der Verkehrsmenge (Summe der beförderten Personen) und der von dieser dabei zurückgelegten Wegstrecke in km.',
    },
    {
      id: 'plug--in-hybrid',
      title: 'Plug-In Hybrid',
      text: 'Ein Plug-in-Hybrid ist ein Kraftfahrzeug mit Hybridantrieb, dessen Akku sowohl über den Verbrennungsmotor als auch mit einem Stecker (engl. „plug in“, „einstöpseln“, „anschließen“) am Stromnetz geladen werden kann. Eine häufig verwendete Abkürzung ist PHEV (engl. „plug-in hybrid electric vehicle“).',
    },
    {
      id: 'politische-instrumente',
      title: 'Politische Instrumente',
      text: 'Politische Instrumente sind konkrete Maßnahmen, die zum Erreichen eines gewählten Soll-Zustandes beitragen sollen. Dazu zählen marktwirtschaftliche Instrumente, wie Umweltsteuern, Verbote, Standards oder Anreize.',
    },
    {
      id: 'ressourcenverbrauch',
      title: 'Ressourcenverbrauch',
      text: 'Unter dem Begriff Ressourcenverbrauch versteht man den Arbeitseinsatz sowie den Verbrauch von Gütern, und hier häufig natürliche Rohstoffe der Erde, der zur Erstellung von (höherwertigeren) Gütern und Dienstleistungen aufgewendet wird. So werden bspw. für die Herstellung von Batterien für Elektroautos Lithium, Kobalt, Nickel und weitere Rohstoffe gebraucht. Diese sind in begrenzten Mengen verfügbar, weshalb Recyclingverfahren im industriellen Maßstab künftig wichtiger werden.',
    },
    {
      id: 'schadstoffklasse',
      title: 'Schadstoffklasse',
      text: 'Fahrzeuge stoßen bestimmte Mengen an Schadstoffen durch das Abgas aus. Das ist von Fahrzeugtyp und Alter abhängig. Bei den Schadstoffen spricht man auch von der Abgasemission. Zu den Schadstoffen zählen unter anderem Kohlenstoffmonoxid, Stickstoffoxid, Kohlenwasserstoff und Rußpartikel. Je nachdem, wie viele Schadstoffe ein Auto ausstößt, wird es in eine der verschiedenen Schadstoffklassen eingestuft. Dadurch wird bestimmt, ob ein Auto schadstoffarm ist oder nicht.',
    },
    {
      id: 'sozialticket',
      title: 'Sozialticket',
      text: 'Ein Sozialticket ist ein im Preis ermäßigter Zeitfahrausweis zur Nutzung öffentlicher Verkehrsmittel durch sozial bedürftige Menschen (z.B. Empfänger:innen von Arbeitslosengeld II, Sozialgeld oder von Grundsicherung im Alter und bei Erwerbsminderung).',
    },
    {
      id: 'tempolimit',
      title: 'Tempolimit',
      text: 'Das Tempolimit beschreibt die zulässige Höchstgeschwindigkeit auf einer Strecke. Umgangssprachlich ist damit vor allem eine mögliche generelle Begrenzung der Höchstgeschwindigkeit auf deutschen Autobahnen gemeint.',
    },
    {
      id: 'treibhausgase-und-treibhausgaseffekt',
      title: 'Treibhausgase und Treibhausgaseffekt',
      text: 'Treibhausgase (THG) sind verantwortlich für die Erwärmung der Erdatmosphäre, der sog. Treibhausgaseffekt. Vom Menschen verursachte THG-Emissionen verstärken diesen Effekt und führen zur Erwärmung des Klimas. Den größten Anteil an den durch den Menschen verursachten THG-Emissionen hat mit fast 90 % das Kohlendioxid. Ein weiteres schädliches Treibhausgas ist Methan. Es ist auf 100 Jahre bezogen 34-mal so wirksam wie CO\u2082 und entsteht vor allem bei der Tierhaltung, genauer gesagt im Verdauungstrakt von Wiederkäuern. Kurzfristig ist die Klimawirkung noch deutlich höher.',
    },
    {
      id: 'verbrennerneuzulassungsverbot',
      title: 'Verbrennerneuzulassungsverbot (auch Verbrennerverbot)',
      text: 'Das Verbrennerneuzulassungsverbot meint ein Verbot von Neuzulassungen von Autos, die fossile Kraftstoffe verbrennen. Dazu gehören auch Plug-in-Hybride.',
    },
  ]
}
// <a class="link" data-index="automatisierung-des-verkehrs-autonomer-verkehr">Automatisierung des Verkehrs/ autonomer Verkehr</a>
// <a class="link" data-index="bonus-malus-system">Bonus-Malus-System</a>
// <a class="link" data-index="carsharing">Carsharing</a>
// <a class="link" data-index="co2-kohlenstoffdioxid">CO\u2082 / Kohlenstoffdioxid</a>
// <a class="link" data-index="co2-emissionen">CO\u2082-Emissionen (auch: Kohlenstoff(dioxid)emissionen)</a>
// <a class="link" data-index="co2-preis">CO\u2082-Preis</a>
// <a class="link" data-index="dekarbonisierte-kraftstoffe">Dekarbonisierte Kraftstoffe (auch alternative Kraftstoffe)</a>
// <a class="link" data-index="deliberation">Deliberation</a>
// <a class="link" data-index="dieselprivileg">Dieselprivileg</a>
// <a class="link" data-index="deutschlandtakt">Deutschlandtakt</a>
// <a class="link" data-index="emissionshandelssystem">Emissionshandelssystem</a>
// <a class="link" data-index="emission">Emission</a>
// <a class="link" data-index="emittent">Emittent</a>
// <a class="link" data-index="e-fuels">E-Fuels</a>
// <a class="link" data-index="endenergieverbrauch">Endenergieverbrauch</a>
// <a class="link" data-index="energiesteuer">Energiesteuer</a>
// <a class="link" data-index="erneuerbare-energien-erneuerbarer-strom-erneuerbare-quellen">Erneuerbare Energien/erneuerbarer Strom/erneuerbare Quellen</a>
// <a class="link" data-index="fahrleistung">Fahrleistung</a>
// <a class="link" data-index="fahrzeugkilometer">Fahrzeugkilometer</a>
// <a class="link" data-index="flotte">Flotte</a>
// <a class="link" data-index="flottengrenzwert">Flottengrenzwert</a>
// <a class="link" data-index="flottenmittel">Flottenmittel</a>
// <a class="link" data-index="fossile-energieträger">Fossile Energieträger</a>
// <a class="link" data-index="fossile-kraftstoffe">Fossile Kraftstoffe</a>
// <a class="link" data-index="kaufprämien">Kaufprämien</a>
// <a class="link" data-index="klimaneutralität">Klimaneutralität</a>
// <a class="link" data-index="klimaschutzziele">Klimaschutzziele</a>
// <a class="link" data-index="kobalt">Kobalt</a>
// <a class="link" data-index="ladeinfrastruktur">Ladeinfrastruktur</a>
// <a class="link" data-index="lithium">Lithium</a>
// <a class="link" data-index="nullemissionsfahrzeug">Nullemissionsfahrzeug</a>
// <a class="link" data-index="maut">Maut</a>
// <a class="link" data-index="parkraumbewirtschaftung">Parkraumbewirtschaftung</a>
// <a class="link" data-index="pendlerpauschale">Pendlerpauschale</a>
// <a class="link" data-index="plug--in-hybrid">Plug-In Hybrid</a>
// <a class="link" data-index="politische-instrumente">Politische Instrumente</a>
// <a class="link" data-index="ressourcenverbrauch">Ressourcenverbrauch</a>
// <a class="link" data-index="schadstoffklasse">Schadstoffklasse</a>
// <a class="link" data-index="sozialticket">Sozialticket</a>
// <a class="link" data-index="tempolimit">Tempolimit</a>
// <a class="link" data-index="treibhausgase-und-treibhausgaseffekt">Treibhausgase und Treibhausgaseffekt</a>
// <a class="link" data-index="verbrennerneuzulassungsverbot">Verbrennerneuzulassungsverbot (auch Verbrennerverbot)</a>
