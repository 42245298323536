














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SwitchInput extends Vue {
  @Prop() text!: string
  @Prop() value!: boolean
}
