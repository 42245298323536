export default {
  text: 'Zentrales Merkmal dieses Politikpfades ist ein technologieoffenes Verständnis der Verkehrswende, das vor allem auf Digitalisierung und bisher nicht genutzte Verkehrsformate (z.B. autonomes Fahren) setzt. Ein Wandel zu Elektrofahrzeugen erfolgt vor allem durch Förderung. Gebote oder Verbote im Verkehrssektor werden nur spärlich eingesetzt. Parkgebühren in Innenstädten steigen. Die Anzahl der Pkw erhöht sich nicht weiter, sondern pendelt sich auf dem heutigen Niveau ein. ',
  title: 'Digitalisierung & Technologien',
  detailOverlay: {
    overline: 'Detailexploration',
    headline: 'Digitalisierung & Technologien',
    text: 'Was wäre wenn, … <br />79 Mio. Tonnen CO\u2082 müssen bis 2030 laut Klimaschutzgesetz eingespart werden (gegenüber 2019). Was passiert, wenn wir nicht handeln? ',
    label: 'Wie schwer eine Maßnahme wiegt, wenn man sie nicht ergreift, sehen sie hier.',
    items: [
      {
        iconId: 'trafficJam',
        label: '',
        text: 'Ohne die Preiserhöhung für das Parken in der Innenstadt würden im Jahr 2030 insgesamt nochmal bis zu 0,6 Mt. mehr CO\u2082 ausgestoßen werden.',
        data: 0.8
      },
      {
        iconId: 'bonusMalus',
        label: ' ',
        text: 'Ohne die Bonus-Malus-Maßnahme würden im Jahr 2030 insgesamt nochmal bis zu 4,3 Mt. mehr CO\u2082 ausgestoßen werden.',
        data: 4.3
      },
      {
        iconId: 'flightNo',
        label: '',
        text: 'Sollte die geplante Beimischung von <a class="link" data-index="endenergieverbrauch">Endenergieverbrauch</a> zu Benzin/Diesel im Jahr 2030 von 10 % nicht erreicht werden, zum Beispiel weil sie nicht zur Verfügung stehen, würden in diesem Pfad im Jahr 2030 zusätzlich ca. 5,7 Mt. CO\u2082 durch PKW ausgestoßen werden. ',
        data: 5.7
      },
    ]
  },
  details: {
    title: 'Flottengrenzwerte',
    general: {
      title: 'Allgemein',
      text: 'Beziehen sich auf gemittelten Grenzwert für CO\u2082-Ausstoß über verkaufte <a class="link" data-index="flotte">Flotte</a> hinweg, den verkaufte Fahrzeuge nicht überschreiten dürfen <br /> <br /> Zurzeit Flottengrenzwertpfad EU-weit festgelegt: PKW 2030 -35% ggü. 2021, LKW 2030 -30% ggü. 2019 <br /> <br /> Optionen: Flottengrenzwerte könnten in Zukunft (a) auf einem Niveau eingefroren werden (um OEMs nicht weiter zu belasten oder weil Wirkung erreicht bzw. mit anderen Maßnahmen erreicht), (b)moderat (2030 -50%) oder (c) streng (2030 -X%) angehoben werden (um sie als Leitmaßnahmen zur Zielerreichung zu nutzen), (d) ein absolutes oberes Limit, also dann keinen Mittelwert mehr, für Neuzulassungen aufweisen (X g/km) oder (e) als Instrument auslaufen (weil z.B. andere Instrumente deren Effekt ersetzen) ',
    },
    path: {
      title: 'Im Pfad “Regulierung”',
      items: [
        {
          overline: 'Lastenverteilung über Einkommens-gruppen hinweg',
          text: 'Regressiv',
        },
        {
          overline: 'Ungleichheit innerhalb der Einkommensgruppe (Indiz für Härtefälle)',
          text: 'Niedrig',
        },
        {
          overline: 'Mechanismus',
          text: 'Obwohl ärmere Haushalte tendenziell kleinere und dadurch sparsamere Autos fahren, ist die Belastung über höhere PKW-Kosten durch die Grenzwert für sie relativ zum Einkommen höher.',
        },
        {
          overline: 'Größenordnung der Effekte',
          text: 'Groß <br /> Grobe Abschätzung (äquivalent zu Schatten-<a class="link" data-index="co2-preis">CO\u2082-Preis</a> von 330€) <br /> <br /> ärmste Fünftel: ~4,2% Belastung des Haushaltseinkommens <br /> <br /> reichste Fünftel: ~2,1% Belastung des Haushaltseinkommens',
        },
      ]
    }
  },
  screws: {
    title: '',
    items: [
      {
        title: 'Bonus für den Kauf von Nullemissionsfahrzeugen',
        iconId: '',
        text: 'Für den Erwerb kleiner und mittelgroßer E-Autos wird ein hoher Bonus gezahlt. Zusätzlich entfällt die jährliche Kfz-Steuer ab 2026. Der Staat stellt dafür zusätzliche Haushaltsmittel bereit.',
        // link: 'https://en.wikipedia.org/wiki/Ramboll',
        // linkText: '',
      },
      {
        title: 'Hohe Parkgebühren in Innenstädten',
        iconId: '',
        text: 'Parken in Innenstädten von Großstädten wird wesentlich teurer. Für das Anwohnerparken steigen die Gebühren bis 2030 auf 500€ pro Jahr und bis 2045 auf 1000€ pro Jahr. Für Menschen, die dort nicht wohnen, verdoppeln sich bis 2030 die Kosten für freies Parken, bis 2045 vervierfachen sie sich. Für das Abstellen von Lkw fallen ebenfalls höhere Gebühren an.',
      },
      {
        title: 'Autonomes Fahren',
        iconId: '',
        text: 'Vor allem auf der Autobahn wird das automatisierte Fahren stark gefördert. Aktuell ist dies beispielsweise in Fahrassistenzsystemen wie dem Spurhalte-Assistenten oder der Abstandswarnung mit automatisierter Bremsfunktion angelegt. Auf dem Land und in Städten unterstützen abseits der ausgebauten Bus- und Bahn-Linien automatisierte Fahrzeuge den ÖPNV, z.B.  als Zubringer zu wichtigen ÖPNV-Knoten und ermöglichen somit auch Menschen in weniger zentralen Wohngebieten den Anschluss an den ÖPNV. Das lässt die Ticketpreise etwas ansteigen.',
      },
      {
        title: 'Förderung synthetischer Kraftstoffe',
        iconId: '',
        text: 'Die Beimischung von synthetischen Kraftstoffen, sogenannten <a class="link" data-index="e-fuels">E-Fuels</a>, die auf Basis von erneuerbaren Energien hergestellt werden, in Benzin und Diesel steigt schneller als in den anderen Pfaden. Im Jahr 2030 liegt der Anteil bereits bei 10%. Autohersteller können so auch einen Teil der verkauften Verbrenner-Pkw zur Einhaltung ihrer CO\u2082-Flottengrenzwerte anrechnen. ',
      },
    ],
    button: 'Detailexploration',
  },
  description: {
    items: [
      {
        title: 'Entwicklung PKW ',
        iconId: '',
        items: [
          {
            title: 'PKW-Bestand',
            text: 'Die Zusammensetzung des gesamten PKW-Bestands in Deutschland ändert sich stark. 2030 sind 20 % aller Fahrzeuge Elektro-PKW, 2045 ist es die überwiegende Mehrheit. Plug-in-Hybrid-Fahrzeuge spielen nur als Brückentechnologie eine Rolle. Aufgrund des hohen Energiebedarfs für Wasserstoff beginnt die Zukunft für Wasserstoff-Autos erst spät. Verbrenner gibt es 2045 noch, diese werden aber emissionsfrei mit synthetischen Kraftstoffen betrieben werden.',
            dataVisual: {
              type: 'bar',
              maxData: 48000000,
              label: 'PKW: ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Gesamt', data: 47715977 },
                    { label: 'Verbrenner', data: 47473490 },
                    { label: 'Elektro-PKW', data: 138731 },
                    { label: 'Plug-In-Hybrid', data: 103756 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Gesamt', data: 47568985 },
                    { label: 'Verbrenner', data: 33725895 },
                    { label: 'Elektro-PKW', data: 9028410 },
                    { label: 'Plug-In-Hybrid', data: 4814680 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Gesamt', data: 46349343 },
                    { label: 'Verbrenner', data: 8337206 },
                    { label: 'Elektro-PKW', data: 33113578 },
                    { label: 'Plug-In-Hybrid', data: 4892303 },
                    { label: 'Wasserstoff PKW', data: 6256 },
                  ]
                },
              ]
            }
          },
          {
            title: 'Pkw-Besitz-Rate',
            text: 'Die Anzahl der PKW auf 1000 Einwohner erhöht sich nicht weiter, sondern pendelt sich auf dem heutigen Niveau ein.',
            dataVisual: {
              type: 'circle',
              label: 'Pkw pro <br /> 1.000 Einwohner:innen',
              items: [
                {
                  label: 'Jetzt',
                  data: 574
                },
                {
                  label: '2030',
                  data: 570
                },
                {
                  label: '2045',
                  data: 561
                },
              ]
            }
          },
        ]
      },
      // {
      //   title: 'Wie entwickeln sich Rad-/Fußverkehr?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Fuß',
      //       text: '',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 36238286522 },
      //               { label: 'Stadt', data: 26498840336 },
      //               { label: 'Land', data: 10109019631 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 40696037560 },
      //               { label: 'Stadt', data: 29850898039 },
      //               { label: 'Land', data: 10845139521 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 41065730109 },
      //               { label: 'Stadt', data: 30678030829 },
      //               { label: 'Land', data: 10510930129 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Rad',
      //       text: 'Die Menschen gehen mehr zu Fuß, bis 2045 um ca. 10 % mehr, der große Anteil dieser Steigerung findet in Städten statt. <br />Der Radverkehr steigt auch, bis 2045 um 25 %. Wieder findet die größere Veränderung in der Stadt statt, aber auch auf dem Land legt der Radverkehr zu. ',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 44488614277 },
      //               { label: 'Stadt', data: 30725698193 },
      //               { label: 'Land', data: 10114915450 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 49613253255 },
      //               { label: 'Stadt', data: 37988455152 },
      //               { label: 'Land', data: 11624798104 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 56900449253 },
      //               { label: 'Stadt', data: 46171616650 },
      //               { label: 'Land', data: 13157897935 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
      {
        title: 'Entwicklung öffentliche Verkehrsmittel',
        iconId: '',
        items: [
          {
            title: 'Nachfrage nach ÖPNV & Fernbahn',
            text: 'Die Nachfrage für den ÖPNV in der Stadt steigt bis 2045 um etwas mehr als 20%, auf dem Land steigt die Nachfrage nur leicht. Die Nachfrage für Bahnreisen im Fernverkehr hat einen Zugewinn von 50%, unter anderem durch das verbesserte Angebot auf den letzten Kilometern bis zur Haustür.',
            dataVisual: {
              type: 'bar',
              maxData: 140000000000,
              label: 'Personenkilometer',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Stadt', data: 100769895169 },
                    { label: 'Land', data: 21429048023 },
                    { label: 'Fernbahn', data: 40143546361 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Stadt', data: 123314695510 },
                    { label: 'Land', data: 24750198382 },
                    { label: 'Fernbahn', data: 60896288700 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Stadt', data: 116277466127 },
                    { label: 'Land', data: 22318893940 },
                    { label: 'Fernbahn', data: 77489975982 },
                  ]
                },
              ]
            }
          },
        ]
      },
      {
        title: 'Wer bewegt sich wie auf Stadt-/Landstrecken?',
        iconId: '',
        items: [
          {
            title: 'Verkehrsmix',
          },
          {
            title: 'Stadt',
            text: '',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 52 },
                    { label: 'Fuß', data: 25 },
                    { label: 'Rad', data: 13 },
                    { label: 'ÖPNV', data: 11 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 45 },
                    { label: 'Fuß', data: 27 },
                    { label: 'Rad', data: 15 },
                    { label: 'ÖPNV', data: 13 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Pkw', data: 42 },
                    { label: 'Fuß', data: 28 },
                    { label: 'Rad', data: 17 },
                    { label: 'ÖPNV', data: 12 },
                  ]
                },
              ]
            },
          },
          {
            title: 'Land',
            text: 'Stadtbewohner:innen reduzieren ihre PKW-Nutzung und steigen im Gegenzug vermehrt auf Fuß und Rad um. Die Nutzung öffentlicher Verkehrsmittel bleibt gleich.</br>Bei Landbewohner:innen reduziert sich die PKW-Nutzung kaum. Fuß- und Radverkehr steigen leicht.',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 63 },
                    { label: 'Fuß', data: 21 },
                    { label: 'Rad', data: 10 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 60 },
                    { label: 'Fuß', data: 23 },
                    { label: 'Rad', data: 11 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Pkw', data: 59 },
                    { label: 'Fuß', data: 23 },
                    { label: 'Rad', data: 12 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
              ]
            },
          }
        ]
      },
      // {
      //   title: 'Wieviel Stadt-/Landbewohner:innen haben eigene Autos?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Stadt',
      //       text: '',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 545
      //           },
      //           {
      //             label: '2030',
      //             data: 532
      //           },
      //           {
      //             label: '2045',
      //             data: 507
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Land',
      //       text: 'Die Anzahl der PKW in der Stadt reduziert sich, während sie auf dem Land in etwa gleich bleibt. ',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 625
      //           },
      //           {
      //             label: '2030',
      //             data: 637
      //           },
      //           {
      //             label: '2045',
      //             data: 650
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
    ],
  },
  button: 'Zurück zur Pfadübersicht',
  buttonLink: '/path-overview'
}
