export default {
  itemsLeft: [
    { name: 'Start', iconLeftId: 'house', route: '/home' },
    { name: 'Test', iconLeftId: 'pen', route: '/configurator', },
    {
      name: 'Pfade', iconLeftId: 'path', iconRightId: 'arrowDown',
      items: [
        {
          name: 'Übersicht',
          route: '/path-overview',
        },
        {
          name: 'Regulierung',
          route: '/path/0',
        },
        {
          name: 'Digitalisierung & Technologien',
          route: '/path/1',
        },
        {
          name: 'Markt / CO\u2082 Preis',
          route: '/path/2',
        },
        {
          name: 'Neue Mobilität',
          route: '/path/3',
        },
      ]
    },
    { name: 'Auswirkungen', route: '/impact', },
  ],
  itemsRight: [
    { name: 'Glossar', iconLeftId: 'glossar', route: '/glossar' },
  ]
}