


























































































import { Component, Prop, Vue } from 'vue-property-decorator'

import imageCentral from '../assets/images/world-central.svg'
import imageDecentral from '../assets/images/world-decentral.svg'
import imageMapCentral from '../assets/images/world-map-central.svg'
import imageMapDecentral from '../assets/images/world-map-decentral.svg'
import imageScrewTechnologyCentral from '../assets/images/screw-technology-central.svg'
import imageScrewTechnologyDecentral from '../assets/images/screw-technology-decentral.svg'
import imageScrewDivideCentral from '../assets/images/screw-divide-central.svg'
import imageScrewDivideDecentral from '../assets/images/screw-divide-decentral.svg'
import imageScrewBarCentral from '../assets/images/screw-bar-central.svg'
import imageScrewBarDecentral from '../assets/images/screw-bar-decentral.svg'
import imageScrewNetCentral from '../assets/images/screw-net-central.svg'
import imageScrewNetDecentral from '../assets/images/screw-net-decentral.svg'
import imageScrewBarCircle from '../assets/images/screw-bar-circle.svg'
import imageScaleRegulation from '../assets/images/scale-regulation.svg'
import imageScaleDigitalization from '../assets/images/scale-digitalization.svg'
import imageScaleMarket from '../assets/images/scale-market.svg'
import imageScaleMobility from '../assets/images/scale-mobility.svg'

@Component({
})
export default class AdviceIllustration extends Vue {
  @Prop() image!: string

  imageCentral = imageCentral
  imageDecentral = imageDecentral
  imageMapCentral = imageMapCentral
  imageMapDecentral = imageMapDecentral
  imageScrewTechnologyCentral = imageScrewTechnologyCentral
  imageScrewTechnologyDecentral = imageScrewTechnologyDecentral
  imageScrewDivideCentral = imageScrewDivideCentral
  imageScrewDivideDecentral = imageScrewDivideDecentral
  imageScrewBarCentral = imageScrewBarCentral
  imageScrewBarDecentral = imageScrewBarDecentral
  imageScrewNetCentral = imageScrewNetCentral
  imageScrewNetDecentral = imageScrewNetDecentral
  imageScrewBarCircle = imageScrewBarCircle
  imageScaleRegulation = imageScaleRegulation
  imageScaleDigitalization = imageScaleDigitalization
  imageScaleMarket = imageScaleMarket
  imageScaleMobility = imageScaleMobility
}
