







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Chart } from 'chart.js'

@Component
export default class DataVisualBarTwo extends Vue {
  @Prop() maxData!: number
  @Prop() minData!: number
  @Prop() label!: string
  @Prop() items!: {
    label: string
    items: {
      label: string
      items: {
        label: string, data: number
      }[]
    }[]
  }[]

  tabActiveIndex = 0
  chart: Chart | null = null
  colors: string[] = [
    '#89D5D5',
    '#81AFDD'
  ]

  get tabs(): string[] {
    return this.items.map((l) => l.label)
  }

  get dataItems() {
    return this.items.map((item) => item.items)
  }

  get dataCurrent() {
    return this.dataItems[this.tabActiveIndex]
  }

  get datasets() {
    const dataMap: { [k: string]: number[] } = {}

    this.dataCurrent.forEach((item) => {
      item.items.forEach((dataItem) => {
        if (dataMap[dataItem.label] === undefined) {
          dataMap[dataItem.label] = [dataItem.data]
        } else {
          dataMap[dataItem.label].push(dataItem.data)
        }
      })
    })

    return Object.keys(dataMap)
      .map((key, index) => ({
        label: key,
        data: dataMap[key],
        backgroundColor: this.colors[index],
        borderRadius: 2,
        maxBarThickness: 24,
      }))
  }

  $refs!: {
    chart: HTMLCanvasElement
  }

  @Watch('tabActiveIndex')
  watchIndex() {
    this.chart?.data.datasets.forEach((dataset, index) => {
      dataset.data = this.datasets[index].data
    })

    this.chart?.update()
  }

  getAxisConversionFactorAndLabel(val) {
    if (typeof val === 'string') {
      return [1, '']
    }

    const TSD = 1000
    const MIL = 1000000
    const BIL = 1000000000

    if (val > BIL) {
      return [BIL, 'Mrd.']
    }

    if (val > MIL) {
      return [MIL, 'Mio.']
    }

    if (val > TSD) {
      return [TSD, 'Tsd.']
    }

    return [1, '']

  }

  mounted() {
    this.chart = new Chart(this.$refs.chart, {
      type: 'bar',
      data: {
        labels: this.dataCurrent.map((i) => i.label),
        datasets: this.datasets,
      },
      options: {
        plugins: {
          tooltip: {
            backgroundColor: '#fff',
            titleColor: '#343A40',
            bodyColor: '#343A40',
            borderColor: '#91AFCD',
            borderWidth: 2,
            callbacks: {
              title: () => '',
              label: (data) => {
                if (typeof data.raw === 'string') {
                    return data.formattedValue
                }
                const value = parseFloat(data.raw)
                const [conversionFactor, conversionLabel] = this.getAxisConversionFactorAndLabel(value)
                if (conversionFactor===1){
                    return `${parseFloat(value).toString().replace('.',',')}${this.label}`
                }
                return `${((value/conversionFactor).toFixed(1)).toString().replace('.', ',')} ${conversionLabel}`.trim()
              },
            }
          },
          legend: {
            position: 'bottom'
          },
        },
        scales: {
          y: {
            suggestedMax: this.maxData,
            min: this.minData,
            grid: { display: false, },
            beginAtZero: true,
            ticks: {
              count: 5,
              callback(val) {
                if (typeof val === 'string') {
                  return val
                }

                return `${(val).toFixed(2)} €`
              },
            },
          },
          x: {
            grid: { display: false, },
          },
        }
      }
    })
  }
}
