










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AnswerCard extends Vue {
  @Prop() title!: string
  @Prop() text!: string
}
