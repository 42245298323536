// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".splash-screen[data-v-3e7f456e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:100%;background-size:cover;position:absolute;top:0;left:0;cursor:pointer}.splash-screen__text-box[data-v-3e7f456e]{text-align:center;position:absolute;width:100%;top:40%}.splash-screen__text-box .title[data-v-3e7f456e]{top:50%;background:-webkit-linear-gradient(#d25689,#d25656);-webkit-background-clip:text;-webkit-text-fill-color:transparent;font-weight:600;font-size:4.5rem;line-height:53px}.splash-screen__text-box .subtitle[data-v-3e7f456e]{color:#096390;font-weight:500;font-size:1.8rem;line-height:25px;text-transform:uppercase}.splash-screen .bottom-line[data-v-3e7f456e]{position:absolute;width:100%;text-align:center;bottom:9rem;color:#7c858e;font-weight:400;font-size:1.7rem;line-height:30px}", ""]);
// Exports
module.exports = exports;
