export default {
  title: 'Auswirkungen: Vergleich der vier Pfade',
  text: ' ',
  accordion: {
    title: '',
    items: [
      {
        iconId: '',
        title: 'Reduzieren der CO\u2082-Emissionen bis 2030',
        text: 'Die größte CO\u2082-Minderung erreicht der Pfad “Neue Mobilität”. Mit 78,9 Mio. Tonnen CO\u2082 liegen die Emissionen des Verkehrs unter dem Zielwert des Bundes-Klimaschutzgesetzes für 2030 (85 Mio. Tonnen CO\u2082).  Die anderen Pfade erreichen bis 2030 das für den Verkehrssektor gesetzte Ziel des Klimaschutzgesetzes nicht.',
        items: [
          {
            dataVisual: {
              type: 'bar',
              maxData: 164,
              label: ' Mt CO\u2082',
              items: [
                {
                  label: '2030',
                  items: [
                    { label: '2019', data: 164.0 },
                    { label: 'Regulierung', data: 86.5 },
                    { label: 'Digitalisierung', data: 102.0 },
                    { label: 'Markt', data: 91.4 },
                    { label: 'Neue Mobilität', data: 78.9 },
                  ]
                },
              ]
            }
          },
        ]
      },
      {
        iconId: '',
        title: 'Kosten pro gefahrenem Kilometer',
        text: 'Der Preisanstieg pro gefahrenem Kilometer ist in der Stadt und auf dem Land fast gleich. Die Kosten für den ÖPNV bleiben gleich oder steigen ganz leicht an. Die Belastung auf dem Land ist dabei nur minimal größer als in der Stadt. Nur im Pfad Neue Mobilität fahren Menschen unabhängig von ihrem Wohnort gratis im ÖPNV.',
        items: [
          {
            title: 'Pkw-Kosten pro km inkl. Unterhalt & Wertverlust ',
            dataVisual: {
              type: 'bar-two',
              maxData: .65,
              minData: .0,
              label: '€',
              items: [
                {
                  label: '2030',
                  items: [
                    {
                      label: 'Regulierung	',
                      items: [
                        { label: 'Stadt', data: 0.54 },
                        { label: 'Land', data: 0.52 },
                      ]
                    },
                    {
                      label: 'Digitalisierung',
                      items: [
                        { label: 'Stadt', data: 0.56 },
                        { label: 'Land', data: 0.53 },
                      ]
                    },
                    {
                      label: 'Markt',
                      items: [
                        { label: 'Stadt', data: 0.57 },
                        { label: 'Land', data: 0.53 },
                      ]
                    },
                    {
                      label: 'Neue Mobilität',
                      items: [
                        { label: 'Stadt', data: 0.56 },
                        { label: 'Land', data: 0.51 },
                      ]
                    }
                  ]
                },
                {
                  label: '2045',
                  items: [
                    {
                      label: 'Regulierung	',
                      items: [
                        { label: 'Stadt', data: 0.55 },
                        { label: 'Land', data: 0.52 },
                      ]
                    },
                    {
                      label: 'Digitalisierung',
                      items: [
                        { label: 'Stadt', data: 0.56 },
                        { label: 'Land', data: 0.52 },
                      ]
                    },
                    {
                      label: 'Markt',
                      items: [
                        { label: 'Stadt', data: 0.54 },
                        { label: 'Land', data: 0.49 },
                      ]
                    },
                    {
                      label: 'Neue Mobilität',
                      items: [
                        { label: 'Stadt', data: 0.63 },
                        { label: 'Land', data: 0.47 },
                      ]
                    }
                  ]
                },
              ]
            }
          },
          {
            title: 'ÖPNV-Kosten pro km	',
            dataVisual: {
              type: 'bar-two',
              maxData: .65,
              minData: .0,
              label: '€',
              items: [
                {
                  label: '2030',
                  items: [
                    {
                      label: 'Regulierung	',
                      items: [
                        { label: 'Stadt', data: 0.13 },
                        { label: 'Land', data: 0.14 },
                      ]
                    },
                    {
                      label: 'Digitalisierung',
                      items: [
                        { label: 'Stadt', data: 0.15 },
                        { label: 'Land', data: 0.15 },
                      ]
                    },
                    {
                      label: 'Markt',
                      items: [
                        { label: 'Stadt', data: 0.15 },
                        { label: 'Land', data: 0.16 },
                      ]
                    },
                    {
                      label: 'Neue Mobilität',
                      items: [
                        { label: 'Stadt', data: 0.0 },
                        { label: 'Land', data: 0.0 },
                      ]
                    }
                  ]
                },
                {
                  label: '2045',
                  items: [
                    {
                      label: 'Regulierung	',
                      items: [
                        { label: 'Stadt', data: 0.13 },
                        { label: 'Land', data: 0.14 },
                      ]
                    },
                    {
                      label: 'Digitalisierung',
                      items: [
                        { label: 'Stadt', data: 0.18 },
                        { label: 'Land', data: 0.19 },
                      ]
                    },
                    {
                      label: 'Markt',
                      items: [
                        { label: 'Stadt', data: 0.18 },
                        { label: 'Land', data: 0.20 },
                      ]
                    },
                    {
                      label: 'Neue Mobilität',
                      items: [
                        { label: 'Stadt', data: 0.0 },
                        { label: 'Land', data: 0.0 },
                      ]
                    }
                  ]
                },
              ]
            }
          }
        ]
      },
      {
        iconId: '',
        isTextFirst: true,
        title: 'Verteilung finanzieller Lasten',
        text: `
            Bei der Verteilung finanzieller Lasten betrachten wir die zusätzlichen Kosten, die Menschen durch die verkehrspolitischen Maßnahmen in den Pfaden entstehen und wie diese im Verhältnis zum Haushaltseinkommen stehen. Dabei belasten einige Maßnahmen eher einkommensschwache Haushalte, andere Maßnahmen eher höhere Einkommen. Der Gesamteffekt der Maßnahmen ist in jedem Pfad durch den Ausschlag der Waage gekennzeichnet.
            <br /> <br />
            Um Belastungen für Menschen mit niedrigem Einkommen abzufangen, ist vor allem die Rückverteilung von Einnahmen aus dem CO\u2082-Preis entscheidend.
          `,
        items: [
          {
            title: 'Regulierung',
            text: 'Der Regulierungspfad macht durch seine Maßnahmen hauptsächlich die Anschaffung eines Verbrenners teurer. Dies belastet niedrige Einkommen stärker, die auf das Auto angewiesen sind. Das Kosten für das Fahren eines Autos werden in diesem Pfad nur moderat höher.',
            image: 'scale-regulation',
          },
          {
            title: 'Digitalisierung',
            text: 'Verbrenner bleiben weiterhin für eine große Mehrheit der Bevölkerung erschwinglich. Vielfahrende und -reisende (meist einkommensstarke Haushalte) sind stärker vom <a class="link" data-index="co2-preis">CO\u2082-Preis</a> betroffen.',
            image: 'scale-digitalization',
          },
          {
            title: 'CO\u2082-Preis',
            text: 'Trotz des hohen <a class="link" data-index="co2-preis">CO\u2082-Preises</a> werden Haushalte mit niedrigem Einkommen entlastet, da insgesamt mehr Geld für eine Pro-Kopf-Rückverteilung zur Verfügung steht. Wird z.B. eine Kopf-Pauschale ausgezahlt, machen Haushalte mit niedrigem Einkommen Gewinn, da ihr Mobilitätsverhalten im Vergleich zu Haushalten mit höheren Einkommen weniger CO2-lastig ist, z.B. weil sie weniger fliegen oder kleinere Autos fahren. ',
            image: 'scale-market',
          },
          {
            title: 'Neue Mobilität',
            text: 'Sowohl einkommensstarke als auch -schwache Haushalte würden weniger Geld für Mobilität ausgeben müssen. Autos werden nicht viel teurer und es gibt mehr günstige Alternativen durch öffentliche Verkehrsmittel. <br /><br /> Durch den relativ hohen CO2-Preisvon 180 € werden Haushalte mit geringerem Einkommen zusätzlich entlastet.',
            image: 'scale-mobility',
          },
        ]
      },
      {
        iconId: '',
        title: 'Auswirkungen auf Lebensstile',
        text: 'Unsere Gewohnheiten werden sich verändern. Wie viele Menschen weiterhin mit dem eigenen Auto unterwegs sein werden, unterscheidet sich zwischen den Pfaden.  <br /> <br />•	Regulierung <br />Menschen werden zunehmend auf E-Autos, Räder oder den öffentlichen Personenverkehr (Bus & Bahn) umsteigen. Im Alltag entstehen neue Gewohnheiten (z.B. Einplanen von Ladezeiten, mehr Home-Office-Arbeit). <br /><br /> •	Digitalisierung & Technologien <br />Digitale Formate in der Alltagsmobilität nehmen zu (z.B. Handy-Applikationen für Car-Sharing, ÖPNV, sparsames Autofahren etc.). Der Pfad hat eher geringe Auswirkungen auf Gewohnheiten von Autofahrenden, da Verbrenner weiterhin den Verkehr prägen. <br /><br /> •	Markt/<a class="link" data-index="co2-preis">CO\u2082-Preis</a> <br />Die Gewohnheiten verschieben sich in Richtung emissionsarmer Verkehrsmittel. Das bedeutet zum Beispiel neue Reisegewohnheiten, z.B. Bahn statt Flugzeug. Höhere Kosten können die Spielräume mancher Menschen einschränken. <br /><br /> •	Neue Mobilität <br />In Städten: Attraktivere Stadtviertel mit kurzen Wegen bieten neue Räume für Fußgänger:innen und Radfahrende. Mehr Platz in den Städten steht für Grünflächen zur Verfügung. In ländlichen Regionen: Neue Gewohnheiten verändern die Mobilität im Alltag (z.B. mehr Home Office, E-Fahrräder und E-Autos) ',
      },
      {
        iconId: '',
        title: 'Auswirkungen auf Umwelt und Gesundheit',
        text: `
            Energiebedarf
            <br />
            Der Pfad Regulierung erreicht durch den schnellen Umstieg auf E-Fahrzeuge die größte Reduzierung des Gesamtenergieverbrauchs im Vergleich aller Pfade. Das direkte Fahren mit Strom ist deutlich energieeffizienter als die Umwandlung von Strom in synthetische <a class="link" data-index="dekarbonisierte-kraftstoffe">Kraftstoffe</a>, die im Verbrenner zum Einsatz kommen.
            <br /> <br />
            Gesundheit
            <br />
            Alle Pfade haben eine positive Auswirkung auf die Gesundheit. Durch das Zulassungsverbot von Verbrennern sinkt die Schadstoffbelastung in der Luft vor allem in Städten besonders stark. Dies bedeutet mehr gesunde Lebenszeit aufgrund des Rückgangs der CO\u2082-Emissionen im Verkehr.
            <br /> <br />
            •	Regulierung
            <br />
            Verringerung der verlorenen, gesunden Lebensjahre in der deutschen Bevölkerung um 95%
            <br /><br />
            •	Digitalisierung & Technologien
            <br />
            Verringerung der verlorenen gesunden Lebensjahre in der deutschen Bevölkerung um 92%
            <br /><br />
            •	Markt/<a class="link" data-index="co2-preis">CO\u2082-Preis</a>
            <br />
            Verringerung der verlorenen gesunden Lebensjahre in der deutschen Bevölkerung um 88%
            <br /><br />
            •	Neue Mobilität <br />Verringerung der verlorenen gesunden Lebensjahre in der deutschen Bevölkerung um 90%
          `,
      },
    ]
  },
  endText: 'Ende',
  endLink: '/end'
}
