















import { Component, Prop, Vue } from 'vue-property-decorator'
import { STORAGE_KEY_HIDE_ONBOARDING } from '../fixtures/storage'

@Component({
})
export default class Start extends Vue {
  @Prop() data!: {
    imageId: string
    title: string
    text: string
    button: string
  }

  isOnboardingHide = false

  mounted() {
    const data = localStorage.getItem(STORAGE_KEY_HIDE_ONBOARDING)

    this.isOnboardingHide = data === 'true'
  }

  onButton() {
    if (this.isOnboardingHide) {
      this.$router.push({ name: 'Home' })
      return
    }
    this.$router.push({ name: 'Onboarding' })
  }
}
