










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class GlossarOverlay extends Vue {
  @Prop() value!: boolean
  @Prop() currentIndex!: string
  @Prop() items!: {
    id: string
    title: string
    text: string
    link: string
    linkText: string
  }[]

  get_item(){
      if(!this.isNumeric(this.currentIndex)){
        for(let i=0; i<this.items.length; i++){
            if(this.items[i].id===this.currentIndex){
                return this.items[i]
            }
        }
      if(this.currentIndex=='-1'){
        return this.items[-1]
      }
      }else{
        return this.items[parseInt(this.currentIndex)]
      }
  }

  get_text(){
    return this.get_item()?.text
  }

  get_title(){
    return this.get_item()?.title
  }

  isNumeric(str: string) {
      if (typeof str != "string") return false
      return !isNaN(parseInt(str)) &&
             !isNaN(parseFloat(str))
    }


  close() {
    this.$emit('input', false)
    this.$router.replace({ query: { glossar: undefined } })
  }
}

