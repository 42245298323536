





























import { Component, Prop, Vue } from 'vue-property-decorator';

import imageDiscuss from '../assets/images/condition-discuss.svg'
import imageScale from '../assets/images/condition-scale.svg'
import imageGrow from '../assets/images/condition-grow.svg'

@Component
export default class ConditionCard extends Vue {
  @Prop() items!: {
    title: string
    text: string
  }
  @Prop() image!: string
  @Prop() summary!: string

  imageDiscuss = imageDiscuss
  imageScale = imageScale
  imageGrow = imageGrow

  $refs!: {
    text: HTMLElement
  }

  mounted() {
    const $glossarLinks = this.$refs.text.querySelectorAll('.link')

    $glossarLinks.forEach(($link) => {
      $link.addEventListener('click', () => {
        if ($link instanceof HTMLElement) {
          this.$router.push({ query: { glossar: $link.dataset.index || '' } })
        }
      })
    })

    if(this.summary){
        const $glossarLinksSummary = this.$refs.summary.querySelectorAll('.link')

        $glossarLinksSummary.forEach(($link) => {
          $link.addEventListener('click', () => {
            if ($link instanceof HTMLElement) {
              this.$router.push({ query: { glossar: $link.dataset.index || '' } })
            }
          })
        })
    }
  }
}
