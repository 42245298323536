export default {
  overlayTitle: 'Ihr Pfad: {path}',
  overlayText: 'Aufgrund Ihrer Antworten empfehlen wir zum Start, sich mit dem Pfad “{path}” vertraut zu machen.',
  overlayButtonPath: 'Empfohlenen Pfad entdecken',
  overlayButtonOverview: 'Zur Übersicht aller Pfade',
  overline: 'Frage {current} von {total}',
  paths: [
    'Regulierung',
    'Digitalisierung & Technologien',
    'Markt / CO\u2082 Preis',
    'Neue Mobilität',
  ],
  items: [
    {
      title: 'Schärfere Flotten-grenzwerte',
      text: 'Sollten Ihrer Meinung nach schärfere <a class="link" data-index="flottengrenzwert">Flottengrenzwerte</a> im Vergleich zur bisherigen Gesetzgebung für PKWs umgesetzt werden?',
      items: [
        {
          points: [1, 0, 0, 0], title: 'Starke Regulierung', text: 'Relativ schnell sollten Flottengrenzwerte nur noch eine Zulassung von Nullemissionsfahrzeugen zulassen (Verschärfung ab 2025 im Vergleich zur bisherigen Gesetzgebung).'
        },
        {
          points: [0, 1, 0, 0], title: 'Moderate Regulierung', text: 'Erst langfristig sollten die Flottengrenzwerte nur noch eine Zulassung von Nullemissionsfahrzeugen ermöglichen (Verschärfung ab 2030 im Vergleich zur bisherigen Gesetzgebung).'
        },
        {
          points: [0, 0, 1, 1], title: 'Moderate Regulierung und Markt', text: 'Auch langfristig sollten die Flottengrenzwerte theoretisch eine Zulassung von Pkw mit Verbrennungsmotor erlauben (gemäß bisheriger Gesetzgebung).'
        },
      ]
    },
    {
      title: 'CO\u2082-Bepreisung inklusive Rückvergütung',
      text: 'Welche der folgenden CO\u2082-Bepreisungsarten halten Sie für am sinnvollsten? Bitte beachten Sie: Bei allen Varianten gilt, die Einnahmen durch den <a class="link" data-index="co2-preis">CO\u2082-Preis</a> werden wieder vollständig an die Bürgerinnen und Bürger rückvergütet, z.B. indem am Jahresende ein <a class="link" data-index="klimageld">Klimageld</a> ausgezahlt wird. Je höher der <a class="link" data-index="co2-preis">CO\u2082-Preis</a>, um so höher das Klimageld. ',
      items: [
        {
          title: 'Moderater CO\u2082-Preis',
          text: 'Der CO\u2082-Preis sollte moderat steigen (100 €/t CO\u2082 im Jahr 2030, also rund 30 ct/l Aufpreis beim Tanken) und Menschen zu klimaschonendem Verkehr bewegen.',
          points: [1, 0, 0, 0],
        },
        {
          title: 'Niedriger CO\u2082-Preis',
          text: 'Der CO\u2082-Preis sollte möglichst niedrig gehalten werden (maximal 65 €/t bis 2030, also rund 20 ct Aufpreis beim Tanken). ',
          points: [0, 1, 0, 0],
        },
        {
          title: 'Hoher CO\u2082-Preis',
          text: 'Der CO\u2082-Preis soll die wichtigste Maßnahme sein, um die Klimaziele im Verkehr zu erreichen (300  €/t CO\u2082 im Jahr 2030, rund 90 ct/l Aufpreis). ',
          points: [0, 0, 1, 0],
        },
        {
          title: 'Moderater CO\u2082-Preis & Alternativen',
          text: 'Der CO\u2082-Preis sollt spürbar, aber nicht zu hoch werden (maximal 180 €/t bis 2030).',
          points: [0, 0, 0, 1],
        },
      ]
    },
    {
      title: 'Kaufanreize / Bonus Malus System',
      text: 'Bei der Zulassung von Neuwagen kann man Anreize und aber auch „Strafen“ in Form eines Bonus-Malus-Systems anwenden, um <a class="link" data-index="nullemissionsfahrzeug">Nullemissionsfahrzeuge</a> attraktiver zu machen. Wie sollte das Ihrer Meinung eingesetzt werden? ',
      items: [
        {
          points: [1, 0, 0, 0],
          title: 'Prämien für Nullemissionsfahrzeuge & starke Verteuerung von Verbrennern',
          text: 'Kleine und mittelgroße Nullemissionsfahrzeuge sollten durch Prämien stark bezuschusst werden, während die Zulassung von Verbrennern stark verteuert werden sollte.'
        },
        {
          points: [0, 1, 0, 0],
          title: 'Prämien für Nullemissionsfahrzeuge & geringe Verteuerung von Verbrennern',
          text: 'Kleine und  mittelgroße Elektroautos sollten durch Prämien stark bezuschusst werden, während neue Verbrenner geringfügig verteuert werden sollten.'
        },
        {
          points: [0, 0, 1, 0],
          title: 'Moderate Prämien und Verteuerung',
          text: 'Weder Prämien noch Strafgebühren sollten bei der Neuzulassung zu hoch ausfallen.'
        },
        {
          points: [0, 0, 0, 1],
          title: 'Starke Verteuerung von Verbrennern',
          text: 'Nur die Zulassung von Verbrennern soll stark verteuert werden.'
        },
      ]
    },
    {
      title: 'PKW Maut',
      text: 'Welchen der folgenden Vorschläge für eine PKW-<a class="link" data-index="maut">Maut</a> (unabhängig von der Antriebsart) würden Sie am meisten begrüßen?',
      items: [
        {
          title: 'Fahrleistungsabhängige Maut',
          text: 'Eine fahrleistungsabhängige Maut (z.B. 0,01 Euro pro gefahrenen Kilometer ab 2030)',
          points: [1, 1, 0, 0],
        },
        {
          title: 'Moderate City-Maut',
          text: 'Eine City-Maut (z.B 5 € ab 2030 für die Einfahrt in Innenstädte).',
          points: [0, 0, 1, 0],
        },
        {
          title: 'Hohe City-Maut',
          text: 'Eine hohe Stadt Maut (z.B 10 € ab 2030 für die Einfahrt in Innenstädte).',
          points: [0, 0, 0, 1],
        },

      ]
    },
    {
      title: 'Tempolimit',
      text: 'Halten Sie ein <a class="link" data-index="tempolimit">Tempolimit</a> für notwendig?',
      items: [
        { points: [0, 1, 1, 0], title: 'Contra Tempolimit', text: 'Nein, ein Tempolimit halte ich nicht für notwendig. ' },
        { points: [1, 0, 0, 0], title: 'Pro Tempolimit Autobahn & Landstraße', text: 'Ja, ein Tempolimit von 120 km/h auf Autobahnen und 80 km/h auf Landstraßen halte ich für eine gute Maßnahme. ' },
        { points: [0, 0, 0, 1], title: 'Pro Tempolimit Autobahn, Landstraße & innerorts', text: 'Ja, ein Tempolimit von 120 km/h auf Autobahnen und 80 km/h auf Landstraßen sowie 30 km/h innerorts halte ich für begrüßenswert. ' },
      ]
    },
    {
      title: 'Weniger innderdeutsche Flüge, bessere Alternativen',
      text: 'Welchen Umgang mit <a class="link" data-index="innerdeutsche-fluege">innerdeutschen Flügen</a> halten Sie aus der Perspektive des Klimaschutzes für sinnvoll?',
      items: [
        { points: [1, 0, 0, 1], title: 'Verbot', text: 'Innerdeutsche Flügen sollten ab 2030 gesetzlich verboten werden. Dafür sollte Bahnfahren staatlich gefördert und vergünstigt werden. ' },
        { points: [0, 1, 0, 0], title: 'Besteuerung', text: 'Es sollte eine Kerosinsteuer erhoben werden und die Luftverkehrsteuer verteuert werden.' },
        { points: [0, 0, 1, 0], title: 'Starke Besteuerung', text: 'Es sollte eine Kerosinsteuer erhoben, die Luftverkehrsteuer verteuert und der CO\u2082-Preis ab 2025 verdoppelt werden.' },
      ]
    },
    {
      title: 'ÖPNV-Tickets und -Ausbau',
      text: 'Für welche der folgenden Varianten würden Sie sich entscheiden, wenn es um den Ausbau des öffentlichen Personennahverkehrs (ÖPNV) geht?',
      items: [
        { points: [1, 0, 0, 0], title: 'Staatliche Förderung & gleichbleibende Ticketpreise', text: 'Das ÖPNV-Angebot wird mit öffentlichen Geldern verbessert. Die Ticketpreise sollten sich aber nicht erhöhen.' },
        { points: [0, 1, 0, 0], title: 'Digitalisierung und steigende Ticketpreise', text: 'Es sollte mehr Angebote wie selbstfahrende Fahrzeuge zur Beförderung von Personen im ländlichen Raum geben. Dafür dürfen Ticketpreise leicht steigen.' },
        { points: [0, 0, 1, 0], title: 'Staatliche Förderung & steigende Ticketpreise', text: 'Für die Angebotsverbesserung sollen bevorzugt öffentliche Gelder eingesetzt werden. Die Ticketpreise dürfen trotzdem steigen.' },
        { points: [0, 0, 0, 1], title: 'Kostenloser ÖPNV', text: 'Der ÖPNV soll ab 2030 für alle kostenlos sein. Für diese Angebotsverbesserung werden öffentliche Gelder stark erhöht.' },
      ]
    },
    {
      title: 'Bessere Rad- und Fußwege',
      text: 'Welche Förderung von Fuß- bzw. Fahrradwegen sagt Ihnen mehr zu?',
      items: [
        {
          title: 'Verbesserter Radverkehr ',
          points: [1, 1, 1, 0],
          text: 'Es sollten zusätzliche Mittel für den Ausbau der Radinfrastruktur bereitgestellt werden. Trotzdem bleibt der Pkw auch in Städten sehr wichtig und soll seinen Raum behalten.',
        },
        {
          title: 'Verbesserte Stadtplanung',
          points: [0, 0, 0, 1],
          text: 'Städte sollen besonders auf Rad- und Fußverkehr setzen. Fuß- und Radwege sowie Grünanlagen sollen ausgebaut und sicherer werden, wichtige Ziele fußläufig erreichbar sein. Dafür soll die Pkw-Nutzung stark reduziert werden.',
        },
      ]
    },
  ]
}
