






























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AdviceList extends Vue {
  @Prop() items!: {
    headline: string
    subline: string
    isChecked: boolean
  }[]
}
