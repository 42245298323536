


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import logoApp from '../assets/images/app_logo.svg'
import logoGermany from '../assets/images/germany_logo.svg'

@Component({
})
export default class End extends Vue {
  @Prop() data!: {
    imageId: string
    title: string
    text: string
    button: string
    link: string
    linkText: string
  }

  isOverlayOpen = false

  logoApp = logoApp
  logoGermany = logoGermany
}
