export default {
  title: 'Regulierung',
  text: 'Zentrales Merkmal dieses Politikpfades ist, dass klare Verbote und Gebote zur Umgestaltung des Verkehrssystems führen. Leitende Maßnahmen sind besonders scharfe Flottengrenzwerte, ein Zulassungsverbot von Verbrennerneufahrzeugen sowie ein ausgeprägtes Bonus-Malus-System. Begleitet werden diese Maßnahmen durch Einfahrverbote in die Innenstädte von Metropolen und Tempolimits inner- und außerorts. Fahrzeuge mit fossilem Antrieb werden schnell und weitgehend durch elektrische Fahrzeuge verdrängt. Die Anzahl der Pkw erhöht sich nicht weiter, sondern pendelt sich auf dem heutigen Niveau ein.',
  detailOverlay: {
    overline: 'Detailexploration',
    headline: 'Regulierung',
    text: 'Was wäre wenn, … <br />79 Mio. Tonnen CO\u2082 müssen bis 2030 laut Klimaschutzgesetz eingespart werden (gegenüber 2019). Was passiert, wenn wir nicht handeln? ',
    label: 'Wie schwer eine Maßnahme wiegt, wenn man sie nicht ergreift, sehen sie hier.',
    items: [
      {
        iconId: 'trafficJam',
        label: '',
        text: 'Ohne eine Verschärfung der <a class="link" data-index="flottengrenzwert">Flottengrenzwerte</a> um weitere 28 % gegenüber der heutigen gesetzlichen Regelung für das Jahr 2030 würden in diesem Pfad im Jahr 2030 durch PKW nochmal 27,6 Mio CO\u2082 mehr ausgestoßen werden.',
        data: 27.6
      },
      {
        iconId: 'bonusMalus',
        label: ' ',
        text: 'Ohne hohe Bonus- und Malus-Zahlungen würden im Jahr 2030 insgesamt nochmal bis zu 8,5 Mt. mehr CO\u2082 ausgestoßen werden. ',
        data: 8.5
      },
      {
        iconId: 'flightNo',
        label: '',
        text: 'Würden in diesem Pfad innerdeutsche Flüge nicht verboten werden, so würden durch diese im Jahr 2030 insgesamt nochmal 1,5 Mt. mehr CO\u2082 ausgestoßen werden.',
        data: 1.5
      },
    ]
  },
  details: {
    title: 'Flottengrenzwerte',
    general: {
      title: 'Allgemein',
      text: 'Beziehen sich auf gemittelten Grenzwert für CO\u2082-Ausstoß über verkaufte <a class="link" data-index="flotte">Flotte</a> hinweg, den verkaufte Fahrzeuge nicht überschreiten dürfen <br /> <br /> Zurzeit Flottengrenzwertpfad EU-weit festgelegt: PKW 2030 -35% ggü. 2021, LKW 2030 -30% ggü. 2019 <br /> <br /> Optionen: Flottengrenzwerte könnten in Zukunft (a) auf einem Niveau eingefroren werden (um OEMs nicht weiter zu belasten oder weil Wirkung erreicht bzw. mit anderen Maßnahmen erreicht), (b)moderat (2030 -50%) oder (c) streng (2030 -X%) angehoben werden (um sie als Leitmaßnahmen zur Zielerreichung zu nutzen), (d) ein absolutes oberes Limit, also dann keinen Mittelwert mehr, für Neuzulassungen aufweisen (X g/km) oder (e) als Instrument auslaufen (weil z.B. andere Instrumente deren Effekt ersetzen) ',
    },
    path: {
      title: 'Im Pfad “Regulierung”',
      items: [
        {
          overline: 'Lastenverteilung über Einkommens-gruppen hinweg',
          text: 'Regressiv',
        },
        {
          overline: 'Ungleichheit innerhalb der Einkommensgruppe (Indiz für Härtefälle)',
          text: 'Niedrig',
        },
        {
          overline: 'Mechanismus',
          text: 'Obwohl ärmere Haushalte tendenziell kleinere und dadurch sparsamere Autos fahren, ist die Belastung über höhere PKW-Kosten durch die Grenzwert für sie relativ zum Einkommen höher.',
        },
        {
          overline: 'Größenordnung der Effekte',
          text: 'Groß <br /> Grobe Abschätzung (äquivalent zu Schatten-<a class="link" data-index="co2-preis">CO\u2082-Preis</a>von 330€) <br /> <br /> ärmste Fünftel: ~4,2% Belastung des Haushaltseinkommens <br /> <br /> reichste Fünftel: ~2,1% Belastung des Haushaltseinkommens',
        },
      ]
    }
  },
  screws: {
    title: '',
    items: [
      {
        title: 'Flottengrenzwerte und Verbrennerneuzulassungsverbot',
        iconId: '',
        text: 'Der CO\u2082-Ausstoß für PKW-Neuzulassungen im Jahr 2025 muss im Mittelwert über die gesamte <a class="link" data-index="flotte">Flotte</a> der Neuzulassungen hinweg um 26% gegenüber 2021 sinken, im Jahr 2030 um 65%. Das ist deutlich strenger als bisher vorgesehen. Ab dem Jahr 2035 dürfen keine neuen Verbrennerfahrzeuge mehr neu zugelassen werden. Dass heißt, die Flottengrenzwerte für Neuzulassungen sinken auf 0 g CO\u2082/km.',
        // link: 'https://en.wikipedia.org/wiki/Ramboll',
        // linkText: '',
      },
      {
        title: 'Bonus-Malus-Sytem',
        iconId: '',
        text: 'Ab 2026 werden hohe Gebühren für die Neuzulassung eines Verbrenners fällig, mit denen hohe Prämien für kleine und mittelgroße Nullemissionsfahrzeuge finanziert werden. Dafür entfällt die heute übliche jährliche Kfz-Steuer.',
      },
      {
        title: 'Verbot innerdeutscher Flüge',
        iconId: '',
        text: 'Innerdeutsche Flüge werden ab 2030 komplett verboten. Gleichzeitig wird der Zugverkehr ausgebaut.',
      },
    ],
    button: 'Detailexploration',
  },
  description: {
    items: [
      {
        title: 'Entwicklung PKW ',
        iconId: '',
        items: [
          {
            title: 'PKW-Bestand',
            text: 'Die Zusammensetzung des gesamten PKW-Bestands in Deutschland ändert sich stark. Durch die strengen Flottengrenzwerte in diesem Pfad wird es einen schnellen Hochlauf der Elektromobilität geben: Während 2030 bereits ein Viertel aller Fahrzeuge Elektro-PKW sind, fährt 2045 die überwiegende Mehrheit emissionslos. Plug-in-Hybrid-Fahrzeuge spielen nur als Brückentechnologie eine Rolle. Aufgrund des hohen Energiebedarfs für Wasserstoff beginnt die Zukunft für Wasserstoff-PKW erst spät. Verbrenner gibt es 2045 nur noch wenige, diese werden aber emissionsfrei mit synthetischen Kraftstoffen betrieben werden.',
            dataVisual: {
              type: 'bar',
              maxData: 48000000,
              label: 'PKW: ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Gesamt', data: 47715977 },
                    { label: 'Verbrenner', data: 47473490 },
                    { label: 'Elektro-PKW', data: 138731 },
                    { label: 'Plug-In-Hybrid', data: 103756 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Gesamt', data: 47568985 },
                    { label: 'Verbrenner', data: 30642568 },
                    { label: 'Elektro-PKW', data: 12624878 },
                    { label: 'Plug-In-Hybrid', data: 4301538 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Gesamt', data: 46349343 },
                    { label: 'Verbrenner', data: 3606734 },
                    { label: 'Elektro-PKW', data: 41662353 },
                    { label: 'Plug-In-Hybrid', data: 1074300 },
                    { label: 'Wasserstoff PKW', data: 5957 },
                  ]
                },
              ]
            }
          },
          {
            title: 'Pkw-Besitz-Rate',
            text: 'Die Anzahl der PKW auf 1000 Einwohner erhöht sich nicht weiter, sondern pendelt sich auf dem heutigen Niveau ein.',
            dataVisual: {
              type: 'circle',
              label: 'Pkw pro <br /> 1.000 Einwohner:innen',
              items: [
                {
                  label: 'Jetzt',
                  data: 574
                },
                {
                  label: '2030',
                  data: 570
                },
                {
                  label: '2045',
                  data: 561
                },
              ]
            }
          },
        ]
      },
      // {
      //   title: 'Wie entwickeln sich Rad-/Fußverkehr?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Fuß',
      //       text: '',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 36238286522 },
      //               { label: 'Stadt', data: 26498840336 },
      //               { label: 'Land', data: 10109019631 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 39961313271 },
      //               { label: 'Stadt', data: 29295430142 },
      //               { label: 'Land', data: 10665883129 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 40241725182 },
      //               { label: 'Stadt', data: 29954572318 },
      //               { label: 'Land', data: 10380623501 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Rad',
      //       text: 'Die Menschen gehen mehr zu Fuß, bis 2045 um ca. 10 % mehr, der große Anteil dieser Steigerung findet in Städten statt. <br />Der Radverkehr steigt auch, bis 2045 um 25 %. Wieder findet die größere Veränderung in der Stadt statt, aber auch auf dem Land legt der Radverkehr zu. ',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 44488614277 },
      //               { label: 'Stadt', data: 30725698193 },
      //               { label: 'Land', data: 10114915450 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 48726187148 },
      //               { label: 'Stadt', data: 37293289068 },
      //               { label: 'Land', data: 11432898080 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 55690520524 },
      //               { label: 'Stadt', data: 44999767983 },
      //               { label: 'Land', data: 13012196999 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
      {
        title: 'Entwicklung öffentliche Verkehrsmittel',
        iconId: '',
        items: [
          {
            title: 'Nachfrage nach ÖPNV & Fernbahn',
            text: 'Die Nachfrage für den ÖPNV in der Stadt steigt bis 2045 um ca. 15%, während sie sich für Bahnreisen im Fernverkehr mehr als verdoppelt, unter anderem wegen des innerdeutschen Flugverbots. Auf dem Land bleibt die Nachfrage für den ÖPNV in etwa gleich.',
            dataVisual: {
              type: 'bar',
              maxData: 100278318728,
              label: 'Personenkilometer',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Stadt', data: 100769895169 },
                    { label: 'Land', data: 21429048023 },
                    { label: 'Fernbahn', data: 40143546361 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Stadt', data: 112934714545 },
                    { label: 'Land', data: 21727869818 },
                    { label: 'Fernbahn', data: 70390645963 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Stadt', data: 117278318728 },
                    { label: 'Land', data: 21562794725 },
                    { label: 'Fernbahn', data: 89804962643 },
                  ]
                },
              ]
            }
          },
        ]
      },
      {
        title: 'Wer bewegt sich wie auf Stadt-/Landstrecken?',
        iconId: '',
        items: [
          {
            title: 'Verkehrsmix',
          },
          {
            title: 'Stadt',
            text: '',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 52 },
                    { label: 'Fuß', data: 25 },
                    { label: 'Rad', data: 13 },
                    { label: 'ÖPNV', data: 11 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 47 },
                    { label: 'Fuß', data: 27 },
                    { label: 'Rad', data: 14 },
                    { label: 'ÖPNV', data: 12 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Pkw', data: 43 },
                    { label: 'Fuß', data: 28 },
                    { label: 'Rad', data: 17 },
                    { label: 'ÖPNV', data: 13 },
                  ]
                },
              ]
            },
          },
          {
            title: 'Land',
            text: 'Stadtbewohner:innen reduzieren ihre PKW-Nutzung und steigen im Gegenzug auf Fuß und Rad um. Die Nutzung öffentlicher Verkehrsmittel steigt geringfügig.<br />Bei Landbewohner:innen reduziert sich die PKW-Nutzung weniger. Fuß- und Radverkehr steigen aber etwas.',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 63 },
                    { label: 'Fuß', data: 21 },
                    { label: 'Rad', data: 10 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 61 },
                    { label: 'Fuß', data: 23 },
                    { label: 'Rad', data: 12 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Pkw', data: 59 },
                    { label: 'Fuß', data: 23 },
                    { label: 'Rad', data: 13 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
              ]
            },
          }
        ]
      },
      // {
      //   title: 'Wieviel Stadt-/Landbewohner:innen haben eigene Autos?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Stadt',
      //       text: '',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 545
      //           },
      //           {
      //             label: '2030',
      //             data: 532
      //           },
      //           {
      //             label: '2045',
      //             data: 507
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Land',
      //       text: 'Die Anzahl der PKW in der Stadt reduziert sich, während sie auf dem Land in etwa gleich bleibt. ',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 625
      //           },
      //           {
      //             label: '2030',
      //             data: 637
      //           },
      //           {
      //             label: '2045',
      //             data: 650
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
    ],
  },
  button: 'Zurück zur Pfadübersicht',
  buttonLink: '/path-overview'
}
