


















































































































































import { STORAGE_KEY_TRAFFIC_PATH_OVERVIEW_LIST } from '@/fixtures/storage'
import { Component, Prop, Vue } from 'vue-property-decorator'
import imagePath1 from '../assets/images/path_1.svg'
import imagePath2 from '../assets/images/path_2.svg'
import imagePath3 from '../assets/images/path_3.svg'
import imagePath4 from '../assets/images/path_4.svg'

interface AccordionItem {
  title: string
  iconId: string
  text: string
  content: string

  items: {
    text: string
    title: string
    dataVisual?: {
      type: string
      label: string
      maxData?: number
      items: {
        label: string
        data?: number
        items?: {
          label: string, data: number
        }[]
      }[]
    }
  }[]

  link?: string
  linkText?: string
}

@Component({
})
export default class PathSingle extends Vue {
  @Prop() data!: {
    navigation: {
      overline: string
      text: string
    }
    screws: {
      title: string
      text: string
    }
    overlayButtonClose: string
    items: {
      title: string
      text: string
      detailOverlay: {
        overline: string
        headline: string
        text: string
        items: {
          color: string
          iconId: string
          label: string
          text: string
          data: string
        }[]
      }
      details: {
        title: string
        general: {
          title: string
          text: string
        },
        path: {
          title: string
          items: {
            overline: string
            text: string
          }[]
        }
      }
      screws: {
        button: string
        items: AccordionItem[]
      }
      // measures: {
      //   title: string
      //   text: string
      //   items: AccordionItem[]
      // }
      description: {
        items: AccordionItem[]
      }
      button: string
      buttonLink: string
    }[]
  }

  isLinkOverlayOpen = false
  isDetailOverlayOpen = false

  itemsCheckedMap: { [index: string]: boolean } = {}

  images: string[] = [
    imagePath1,
    imagePath2,
    imagePath3,
    imagePath4,
  ]

  mounted() {
    const data = localStorage.getItem(STORAGE_KEY_TRAFFIC_PATH_OVERVIEW_LIST)

    if (data) {
      this.itemsCheckedMap = JSON.parse(data)
      localStorage.setItem(STORAGE_KEY_TRAFFIC_PATH_OVERVIEW_LIST, JSON.stringify({
        ...this.itemsCheckedMap,
        [this.$route.path]: true
      }))
    }

    const $glossarLinks = this.$refs.text.querySelectorAll('.link')

    $glossarLinks.forEach(($link) => {
      $link.addEventListener('click', () => {
        if ($link instanceof HTMLElement) {
          this.$router.push({ query: { glossar: $link.dataset.index || '' } })
        }
      })
    })
  }

  $refs!: {
    text: HTMLElement
  }

  get index() {
    return parseInt(this.$route.params.index)
  }

  get item() {
    return this.data.items[this.index]
  }

  get navigationItems() {
    return this.data.items.map((item, i) => ({
      label: item.title,
      link: `/path/${i}`
    }))
  }

  onLink() {
    this.isLinkOverlayOpen = true
  }

  onDetails() {
    this.isDetailOverlayOpen = true
  }
}
