






















import { Component, Prop, Vue } from 'vue-property-decorator';
import DataVisualBarMapGermany from './DataVisualBarMapGermany.vue';

@Component({
  components: {
    DataVisualBarMapGermany,
  }
})
export default class DataVisualBarMap extends Vue {
  @Prop() items!: {
    label: string
    items: {
      maxData: number
      label: string
      color: string
      items: { data: number, label: string }[]
    }[]
  }[]
  @Prop() maxData!: number

  tabActiveIndex = 0

  get tabs(): string[] {
    return this.items.map((l) => l.label)
  }

  get currentItem() {
    return this.items[this.tabActiveIndex]
  }
}
