

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AdviceCard extends Vue {
  @Prop() headline!: string
  @Prop() iconId!: string
  @Prop() label!: string

  $refs!: {
    text: HTMLElement
  }

  mounted() {
    const $glossarLinks = this.$refs.text.querySelectorAll('.link')

    $glossarLinks.forEach(($link) => {
      $link.addEventListener('click', () => {
        if ($link instanceof HTMLElement) {
          this.$router.push({ query: { glossar: $link.dataset.index || '' } })
        }
      })
    })
  }
}
