


























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DataVisualStackedBar extends Vue {
  @Prop() label!: string
  @Prop() items!: {
    label: string
    items: {
      label: string, data: number
    }[]
  }[]

  tabActiveIndex = 0

  colors: string[] = [
    'green',
    'blue',
    'pink',
    'purple',
  ]

  get tabs(): string[] {
    return this.items.map((l) => l.label)
  }

  get currentItem() {
    return this.items[this.tabActiveIndex]
  }
}
