






import { Component, Prop, Vue } from 'vue-property-decorator';
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component
export default class DataVisualStackedBarVertical extends Vue {
  @Prop() label!: string
  @Prop() items!: {
    label: string
    items: {
      label: string, data: number, color: string
    }[]
  }[]

  chart: Chart | null = null
  tabActiveIndex = 0

  $refs!: {
    chart: HTMLCanvasElement
  }

  colors = [
    '#7C858E',
    '#343A40',
    '#D8D8D8',
    '#2A639D',
    '#9AB5D0',
    '#D7D759',
  ]

  getAxisConversionFactorAndLabel(val) {
    if (typeof val === 'string') {
      return [1, '']
    }

    const TSD = 1000
    const MIL = 1000000
    const BIL = 1000000000

    if (val > BIL) {
      return [BIL, 'Mrd.']
    }

    if (val > MIL) {
      return [MIL, 'Mio.']
    }

    if (val > TSD) {
      return [TSD, 'Tsd.']
    }

    return [1, '']

  }

  mounted() {
    const labels = this.items.map((item) => item.label)
    const data = {
      labels: labels,
      datasets: this.items[0].items.map((dataItem, index) => ({
        label: dataItem.label,
        backgroundColor: this.colors[index],
        data: this.items.map((item) => item.items[index].data),
        maxBarThickness: 40,
      }))
    };

    this.chart = new Chart(this.$refs.chart, {
      type: 'bar',
      data: data,
      plugins:[ChartDataLabels],
      options: {
        layout: {
            padding: {
                top: 10, //without data labels on top might be cut
            }
        },
        plugins: {
          tooltip: {
            backgroundColor: '#fff',
            titleColor: '#343A40',
            bodyColor: '#343A40',
            borderColor: '#91AFCD',
            borderWidth: 2,
            callbacks: {
              title: () => '',
              label: (data) => {
                if (typeof data.raw === 'string') {
                    return data.formattedValue
                }
                const value = parseFloat(data.raw)
                const [conversionFactor, conversionLabel] = this.getAxisConversionFactorAndLabel(value)
                return `${((value/conversionFactor).toFixed(1)).toString().replace('.', ',')} ${conversionLabel}`.trim()
              }
            }
          },
          legend: {
            align: 'start',
            position: 'bottom',
            onClick: null,
          },
          title: {
            display: false,
            text: ''
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value, context) => {
                if(context.datasetIndex !== context.chart.data.datasets.length - 1){
                    return ''
                }
                if (typeof value === 'string') {
                    return value
                }
                const dataIndex = context.dataIndex
                function selectIndex(dataset){
                    return dataset.data[this.dataIndex]
                }
                const total = context.chart.data.datasets.map(selectIndex, context
                ).reduce((partialSum, nextVal) => partialSum+nextVal, 0)
                const [conversionfactor, conversionlabel] = this.getAxisConversionFactorAndLabel(total)
                return `${((total/conversionfactor).toFixed(1)).toString().replace('.', ',')} ${conversionlabel}`.trim()
            }
          },
        },
        responsive: true,
        scales: {
          x: {
            grid: { display: false, },
            stacked: true,
          },
          y: {
            title: {
                display: this.label!=='',
                text: this.label,
            },
            grid: { display: false, },
            stacked: true,
            ticks: {
              display: false,
            }
          }
        }
      }
    })
  }
}
