





































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DataVisualBarMapGermany extends Vue {
  @Prop() items!: { data: number, label: string }[]
  @Prop() background!: string
  @Prop() maxData!: number

  get regions() {
    return JSON.parse(JSON.stringify(this.items)).reverse() // for some reason the map is reversed
  }

  get opacityDataMap(): number[] {
    return this.regions.map((it: { data: number }) => {
      const opacity = (it.data / this.maxData)

      return opacity > 1 ? 1 : opacity
    })
  }
}
