



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PathNavigation extends Vue {
  // @Prop() text!: string
  @Prop() overline!: string
  @Prop() items!: {
    label: string
    link: string
  }[]
  @Prop() itemActiveIndex!: number
}

