
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tabs extends Vue {
  @Prop() value!: number // active index
  @Prop() tabs!: string[]
  @Prop() title!: string
  @Prop() isBackgroundHidden!: boolean
}
