















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})
export default class OverlayWebsite extends Vue {
  @Prop() isOpen!: boolean
  @Prop() link!: string
  @Prop() linkText!: string
}
