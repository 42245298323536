










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SplashScreen extends Vue {
  @Prop() title!: string
  @Prop() subtitle!: string
  @Prop() bottomLine!: string
}
