








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextElement extends Vue {
  @Prop() overline!: string
  @Prop() headline!: string
  @Prop() text!: string
  @Prop() isBig!: boolean

  $refs!: {
    text: HTMLElement
  }
  mounted() {
    const $glossarLinks = this.$refs?.text?.querySelectorAll('.link')

    if ($glossarLinks!==undefined){
        $glossarLinks.forEach(($link) => {
          $link.addEventListener('click', () => {
            if ($link instanceof HTMLElement) {
              this.$router.push({ query: { glossar: $link.dataset.index || '' } })
            }
          })
        })
    }
  }
}
