export default {
  overline: 'Übersicht',
  headline: 'Politikpfade',
  text: '',
  items: [
    {
      headline: 'Regulierung',
      subline: '',
      link: '/path/0',
    },
    {
      headline: 'Digitalisierung & Technologien',
      subline: '',
      link: '/path/1',
    },
    {
      headline: 'Markt / CO\u2082 Preis',
      subline: '',
      link: '/path/2',
    },
    {
      headline: 'Neue Mobilität',
      subline: '',
      link: '/path/3',
    },
  ],
  button: 'Zu den Auswirkungen',
  buttonLink: '/impact',
}
