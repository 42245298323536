









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const MAX_DATA = 80

@Component
export default class DetailOverlay extends Vue {
  @Prop() isOpen!: boolean
  @Prop() data!: {
    overline: string
    headline: string
    text: string
    label: string
    items: {
      color: string
      iconId: string
      label: string
      text: string
      data: number
    }[]
  }

  enabledSwitched: boolean[] = []

  created() {
    this.enabledSwitched = this.data.items.map((_, index) => index === 0)
  }

  onSwitch(clickedIndex: number) {
    this.enabledSwitched = this.data.items.map((_, index) => index === clickedIndex)
  }

  $refs!: {
    text: HTMLElement
  }

  @Watch('isOpen')
  watchIsopen() {
    if (this.isOpen === false) return

    setTimeout(() => {
      const $glossarLinks = this.$refs.text.querySelectorAll('.link')

      $glossarLinks.forEach(($link) => {
        $link.addEventListener('click', () => {
          if ($link instanceof HTMLElement) {
            this.$router.push({ query: { glossar: $link.dataset.index || '' } })
          }
        })
      })
    })
  }

  get currentIndex() {
    return this.enabledSwitched.indexOf(true)
  }

  get tabItems() {
    return [
      {
        items: this.currentIndex >= 0 ? [
          {
            title: this.data.items[this.currentIndex].label,
            percentage: (this.data.items[this.currentIndex].data / MAX_DATA) * 100,
            color: 'gradient',
          },
          {
            title: '',
            percentage: ((MAX_DATA - this.data.items[this.currentIndex].data) / MAX_DATA) * 100,
            color: 'gray',
          }
        ] : []
      }
    ]
  }
}
