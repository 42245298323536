import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Configurator from '../../../views/Configurator.vue'
import Glossar from '../../../views/Glossar.vue'
import HomeTraffic from '../../../views/HomeTraffic.vue'
import Onboarding from '../../../views/Onboarding.vue'
import PathOverview from '../../../views/PathOverview.vue'
import PathSingle from '../../../views/PathSingle.vue'
import Splash from '../../../views/Splash.vue'
import Start from '../../../views/Start.vue'
import End from '../../../views/End.vue'
import Impact from '../../../views/Impact.vue'

import dataSplash from '../data/splash'
import dataStart from '../data/start'
import dataOnboarding from '../data/onboarding'
import dataGlossar from '../data/glossar'
import dataConfigurator from '../data/configurator'
import dataPathOverview from '../data/path-overview'
import dataPathSinge from '../data/path-single'
import dataHome from '../data/home'
import dataEnd from '../data/end'
import dataImpact from '../data/impact'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/splash',
    name: 'Splash',
    component: Splash,
    props: {
      data: dataSplash
    }
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
    props: {
      data: dataStart
    }
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    props: {
      data: dataOnboarding
    },
    meta: {
      routeTransition: 'slide-y'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeTraffic,
    props: {
      data: dataHome
    }
  },
  {
    path: '/glossar',
    name: 'Glossar',
    component: Glossar,
    props: {
      data: dataGlossar
    }
  },
  {
    path: '/configurator',
    name: 'Configurator',
    component: Configurator,
    props: {
      data: dataConfigurator
    }
  },
  {
    path: '/path-overview',
    name: 'PathOverview',
    component: PathOverview,
    props: {
      data: dataPathOverview
    }
  },
  {
    path: '/path/:index',
    name: 'PathSingle',
    component: PathSingle,
    props: {
      data: dataPathSinge
    }
  },
  {
    path: '/impact',
    name: 'Impact',
    component: Impact,
    props: {
      data: dataImpact
    }
  },
  {
    path: '/end',
    name: 'End',
    component: End,
    props: {
      data: dataEnd
    }
  },
  {
    path: '*',
    component: Splash,
    props: {
      data: dataSplash
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Do not scroll up if there is a glossary overlay
    if (Object.keys(to.query).includes('glossar')){
      return {}
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0}
    }
  }
})

export default router
