















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})
export default class Glossar extends Vue {
  @Prop() data!: {
    items: {
      title: string
      text: string
      link: string
      linkText: string
    }[]
  }

  isOverlayWebsiteOpen = false
  overlayWebsiteLink = ''
  overlayWebsiteLinkText = ''

  onLink(data: { link: string, linkText: string }) {
    window.open(data.link, '_blank')
    // this.isOverlayWebsiteOpen = true
    // this.overlayWebsiteLinkText = data.linkText
    // this.overlayWebsiteLink = data.link
  }
}
