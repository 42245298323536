

























































import { Component, Prop, Vue } from 'vue-property-decorator'

type Points = [number, number, number, number] // weight of answer for each category

@Component({
})
export default class Configurator extends Vue {
  @Prop() data!: {
    overlayTitle: string
    overlayText: string
    overlayButtonPath: string
    overlayButtonOverview: string
    overline: string
    paths: string[]
    items: {
      overline: string
      title: string
      text: string
      items: {
        points: Points
        title: string
        text: string
      }[]
    }[]
  }

  currentPage = 1
  points: Points = [0, 0, 0, 0]
  isOverlayOpen = false

  get resultTabItems() {
    const totaPoints = this.points.reduce((a, b) => a + b, 0)

    return [
      {
        items: this.data.paths.map((item, index) => ({
          title: item,
          percentage: (this.points[index] / totaPoints) * 100,
          color: ['green', 'blue', 'pink', 'purple'][index]
        }))
          .sort((a, b) => (a.percentage > b.percentage) ? -1 : 1)
      }
    ]
  }

  get highestPointsIndex(): number {
    return this.points.indexOf(Math.max(...this.points))
  }

  get total() {
    return this.data.items.length
  }

  get item() {
    return this.data.items[this.currentPage - 1]
  }

  get overline(): string {
    return this.data.overline
      .replace('{current}', this.currentPage.toString())
      .replace('{total}', this.data.items.length.toString())
  }

  onAnswer(points: Points) {
    if (this.currentPage === this.data.items.length) {
      this.isOverlayOpen = true
      return
    }
    this.currentPage += 1

    this.points.forEach((_, index) => {
      this.$set(this.points, index, this.points[index] + points[index])
    })
  }

  onPath() {
    this.$router.push({
      name: 'PathSingle', params: {
        index: this.highestPointsIndex.toString()
      }
    })
  }

  onClose() {
    this.$router.push({ name: 'PathOverview' })
  }
}
