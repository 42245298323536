














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DataVisualCircle extends Vue {
  @Prop() label!: string
  @Prop() items!: {
    label: string
    data: number
  }[]

  tabActiveIndex = 0
  minSize = 24

  get size(){
    const smallestValueInApp = 342
    const circleAreaRatio = Math.sqrt(this.currentItem.data/smallestValueInApp)
    return this.minSize*circleAreaRatio
  }

  get tabs(): string[] {
    return this.items.map((l) => l.label)
  }

  get currentItem() {
    return this.items[this.tabActiveIndex]
  }

}
