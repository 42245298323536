import path1 from './path-1'
import path2 from './path-2'
import path3 from './path-3'
import path4 from './path-4'

export default {
  navigation: {
    overline: 'Pfad',
  },
  overlayButtonClose: 'Ok',
  screws: {
    title: 'Wie sieht der Personenverkehr der Zukunft aus?',
    text: 'Hier sehen Sie wie sich unser Verkehrsverhalten in diesem Pfad entwickelt.',
  },
  items: [
    path1,
    path2,
    path3,
    path4,
  ]
}