

































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class StackedBarChart extends Vue {
  @Prop({ default: false }) isPercentageShown!: boolean
  @Prop() overline!: string
  @Prop() items!: {
    title: string
    items: {
      title: string
      percentage: number
      color: string
    }[]
  }[]
  @Prop() isListHidden!: boolean

  tabActiveIndex = 0

  get tabs(): string[] {
    return this.items.map((i) => i.title)
  }

  get currentItem() {
    return this.items[this.tabActiveIndex]
  }
}
