








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop() text!: string
  @Prop() iconRight!: boolean
  @Prop() iconLeft!: boolean
  @Prop() isFullWidth!: boolean
  @Prop() isSecondary!: boolean


  get classNames() {
    return [
      { 'button--full-width': this.isFullWidth },
      { 'button--secondary': this.isSecondary },
    ];
  }
}

