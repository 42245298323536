















































import { STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST, STORAGE_KEY_TRAFFIC_PATH_OVERVIEW_LIST, STORAGE_KEY_ENERGY_SCREW_OVERVIEW_LIST } from '@/fixtures/storage'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
})
export default class AppHeader extends Vue {
  @Prop() data!: {
    itemsLeft: {
      name: string
      route: string
      iconId: string
      iconLeftId: string
      iconRightId: string
      items: { name: string, route: string, iconId: string }[]
    }[]
    itemsRight: {
      name: string
      route: string
      iconId: string
      iconLeftId: string
      iconRightId: string
    }[]
  }

  isSubmenuOpenMap: { [index: number]: boolean } = {}

  itemsScrewOverviewListCheckedMap: { [url: string]: boolean } = {}
  itemsTrafficPathOverviewCheckedMap: { [url: string]: boolean } = {}
  itemsScrewConditionsCheckedMap: { [url: string]: boolean } = {}

  mounted() {
    window.addEventListener('click', (e: Event) => {
      if (e.target instanceof HTMLElement && !this.$el.contains(e.target)) {
        this.isSubmenuOpenMap = {}
      }
    })
    this.onRoute()
  }

  @Watch('$route')
  onRoute() {
    const dataScrewOverviewList = localStorage.getItem(STORAGE_KEY_ENERGY_SCREW_OVERVIEW_LIST)
    const dataEnergyConditions = localStorage.getItem(STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST)
    const dataTrafficPathOverviewList = localStorage.getItem(STORAGE_KEY_TRAFFIC_PATH_OVERVIEW_LIST)

    if (dataScrewOverviewList) {
      this.itemsScrewOverviewListCheckedMap = JSON.parse(dataScrewOverviewList)
    }

    if (dataTrafficPathOverviewList) {
      this.itemsTrafficPathOverviewCheckedMap = JSON.parse(dataTrafficPathOverviewList)
    }

    if (dataEnergyConditions) {
      this.itemsScrewConditionsCheckedMap = JSON.parse(dataEnergyConditions)
    }
  }

  onSubmenu(index: number) {
    this.isSubmenuOpenMap[index] = false
  }

  toggleSubmenu(index: number, event: Event) {
    event.preventDefault()

    this.isSubmenuOpenMap = {
      [index]: !this.isSubmenuOpenMap[index]
    }
  }

  get itemsLeft() {
    return this.data.itemsLeft.map((item) => {
      return {
        ...item,
        items: item.items ? item.items.map((child) => ({
          ...child,
          iconId: this.getChildIcon(item.name, child.route) ? 'checkmark' : ' '
        })) : undefined
      }
    })
  }

  getChildIcon(type: string, childRoute: string) {
    switch (type) {
      case 'Stellschrauben': {
        return this.itemsScrewOverviewListCheckedMap[childRoute] ? 'checkmark' : ''
      }
      case 'Pfade': {
        return this.itemsTrafficPathOverviewCheckedMap[childRoute] ? 'checkmark' : ''
      }
      case 'Herausforderungen': {
        return this.itemsScrewConditionsCheckedMap[childRoute] ? 'checkmark' : ''
      }
      default: {
        return ''
      }
    }
  }

  isChildActive(index: number) {
    return this.$route.query.index === index.toString()
  }
}
