export default {
  text: 'Zentrales Merkmal dieses Politikpfades ist die Einführung eines starken CO\u2082-Preises als Leitinstrument der Verkehrswende. Der Ausstoß von CO\u2082 wird teuer und ein Anreiz gesetzt, CO\u2082 einzusparen. Dadurch verteuern sich <a class="link" data-index="fossile-kraftstoffe">fossile Kraftstoffe</a> wie Benzin und Diesel, so dass Fahrzeuge mit Verbrennermotor unattraktiver werden. Weitere Instrumente wie z.B. eine City-<a class="link" data-index="maut">Maut</a> werden nur unterstützend eingesetzt. </br></br>Die folgende Stellschraube charakterisiert diesen Pfad:',
  title: 'Markt / CO\u2082 Preis',
  detailOverlay: {
    overline: 'Detailexploration',
    headline: 'Markt / CO\u2082 Preis',
    text: 'Was wäre wenn, … <br />79 Mio. Tonnen CO\u2082 müssen bis 2030 laut Klimaschutzgesetz eingespart werden (gegenüber 2019). Was passiert, wenn wir nicht handeln? ',
    label: 'Wie schwer eine Maßnahme wiegt, wenn man sie nicht ergreift, sehen sie hier.',
         items: [
      {
        iconId: 'trafficJam',
        label: '',
        text: 'Ohne einen im Vergleich zur jetzigen Planung leicht erhöhten CO\u2082-Preis würden in diesem Pfad im Jahr 2030 nochmal bis zu 6 Mio. Tonnen CO\u2082 mehr ausgestoßen werden.',
        data: 6
      },
    ]
  },
  details: {
    title: 'Flottengrenzwerte',
    general: {
      title: 'Allgemein',
      text: 'Beziehen sich auf gemittelten Grenzwert für CO\u2082-Ausstoß über verkaufte <a class="link" data-index="flotte">Flotte</a> hinweg, den verkaufte Fahrzeuge nicht überschreiten dürfen <br /> <br /> Zurzeit Flottengrenzwertpfad EU-weit festgelegt: PKW 2030 -35% ggü. 2021, LKW 2030 -30% ggü. 2019 <br /> <br /> Optionen: Flottengrenzwerte könnten in Zukunft (a) auf einem Niveau eingefroren werden (um OEMs nicht weiter zu belasten oder weil Wirkung erreicht bzw. mit anderen Maßnahmen erreicht), (b)moderat (2030 -50%) oder (c) streng (2030 -X%) angehoben werden (um sie als Leitmaßnahmen zur Zielerreichung zu nutzen), (d) ein absolutes oberes Limit, also dann keinen Mittelwert mehr, für Neuzulassungen aufweisen (X g/km) oder (e) als Instrument auslaufen (weil z.B. andere Instrumente deren Effekt ersetzen) ',
    },
    path: {
      title: 'Im Pfad “Regulierung”',
      items: [
        {
          overline: 'Lastenverteilung über Einkommens-gruppen hinweg',
          text: 'Regressiv',
        },
        {
          overline: 'Ungleichheit innerhalb der Einkommensgruppe (Indiz für Härtefälle)',
          text: 'Niedrig',
        },
        {
          overline: 'Mechanismus',
          text: 'Obwohl ärmere Haushalte tendenziell kleinere und dadurch sparsamere Autos fahren, ist die Belastung über höhere PKW-Kosten durch die Grenzwert für sie relativ zum Einkommen höher.',
        },
        {
          overline: 'Größenordnung der Effekte',
          text: 'Groß <br /> Grobe Abschätzung (äquivalent zu Schatten-CO\u2082-Preis von 330€) <br /> <br /> ärmste Fünftel: ~4,2% Belastung des Haushaltseinkommens <br /> <br /> reichste Fünftel: ~2,1% Belastung des Haushaltseinkommens',
        },
      ]
    }
  },
  screws: {
    title: '',
    items: [
      {
        title: 'CO\u2082-Preis',
        iconId: '',
        text: 'Als Leitinstrument wird der <a class="link" data-index="co2-preis">CO\u2082-Preis</a> besonders hoch angesetzt und lenkt Investitionen deshalb in Richtung klimafreundlicher Produkte. Schon 2025 steigt der <a class="link" data-index="co2-preis">CO\u2082-Preis</a> in diesem Pfad auf 100 €/t CO\u2082. Bis 2030 wächst er schrittweise weiter auf 300 €/t CO\u2082. 2045 liegt er bei 715 €/t CO\u2082. Wichtig: Alle so gewonnenen Einnahmen werden an die Bürger:innen rückverteilt bspw. über jährliche Einmalzahlungen als <a class="link" data-index="klimageld">Klimageld</a>. Es profitieren besonders die Bürger:innen, die sich sehr klimafreundlich verhalten.',
      },
    ],
    button: 'Detailexploration',
  },
  description: {
    items: [
      {
        title: 'Entwicklung PKW ',
        iconId: '',
        items: [
          {
            title: 'PKW-Bestand',
            text: 'Die Zusammensetzung des gesamten PKW-Bestands in Deutschland ändert sich stark. 2030 sind 20 % aller Fahrzeuge bereits Elektro-PKW und 2045 fährt die Mehrheit emissionslos. Plug-in-Hybrid-Fahrzeuge spielen nur als Brückentechnologie eine Rolle. Aufgrund des hohen Energiebedarfs für Wasserstoff beginnt die Zukunft für Wasserstoff-PKW erst spät. Verbrenner gibt es 2045 noch, diese werden aber emissionsfrei mit synthetischen Kraftstoffen betrieben werden.',
            dataVisual: {
              type: 'bar',
              maxData: 48000000,
              label: 'PKW: ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Gesamt', data: 47715977 },
                    { label: 'Verbrenner', data: 47473490 },
                    { label: 'Elektro-PKW', data: 138731 },
                    { label: 'Plug-In-Hybrid', data: 103756 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Gesamt', data: 48228747 },
                    { label: 'Verbrenner', data: 33001835 },
                    { label: 'Elektro-PKW', data: 10066088 },
                    { label: 'Plug-In-Hybrid', data: 5160824 },
                    { label: 'Wasserstoff PKW', data: 0 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Gesamt', data: 48022584 },
                    { label: 'Verbrenner', data: 7659570 },
                    { label: 'Elektro-PKW', data: 32670904 },
                    { label: 'Plug-In-Hybrid', data: 7690175 },
                    { label: 'Wasserstoff PKW', data: 1935 },
                  ]
                },
              ]
            }
          },
          {
            title: 'Pkw-Besitz-Rate',
            text: 'Die Anzahl der Pkw auf 1000 Einwohner erhöht sich nicht weiter, sondern pendelt sich auf dem heutigen Niveau ein.',
            dataVisual: {
              type: 'circle',
              label: 'Pkw pro <br /> 1.000 Einwohner:innen',
              items: [
                {
                  label: 'Jetzt',
                  data: 574
                },
                {
                  label: '2030',
                  data: 578
                },
                {
                  label: '2045',
                  data: 581
                },
              ]
            }
          },
        ]
      },
      // {
      //   title: 'Wie entwickeln sich Rad-/Fußverkehr?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Fuß',
      //       text: '',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 36238286522 },
      //               { label: 'Stadt', data: 26498840336 },
      //               { label: 'Land', data: 10109019631 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 40743507458 },
      //               { label: 'Stadt', data: 29899112033 },
      //               { label: 'Land', data: 10844395425 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 39408126714 },
      //               { label: 'Stadt', data: 28993070779 },
      //               { label: 'Land', data: 9969929020 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Rad',
      //       text: 'Die Menschen gehen mehr zu Fuß, bis 2045 um ca. 10 % mehr, der große Anteil dieser Steigerung findet in Städten statt. <br />Der Radverkehr steigt auch, bis 2045 um 25 %. Wieder findet die größere Veränderung in der Stadt statt, aber auch auf dem Land legt der Radverkehr zu. ',
      //       dataVisual: {
      //         type: 'bar',
      //         maxData: 56000000000,
      //         label: ' km',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             items: [
      //               { label: 'Nah', data: 44488614277 },
      //               { label: 'Stadt', data: 30725698193 },
      //               { label: 'Land', data: 10114915450 },
      //             ]
      //           },
      //           {
      //             label: '2030',
      //             items: [
      //               { label: 'Nah', data: 49909587765 },
      //               { label: 'Stadt', data: 38253168891 },
      //               { label: 'Land', data: 11656418874 },
      //             ]
      //           },
      //           {
      //             label: '2045',
      //             items: [
      //               { label: 'Nah', data: 54610693805 },
      //               { label: 'Stadt', data: 43689294434 },
      //               { label: 'Land', data: 12488434717 },
      //             ]
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
      {
        title: 'Fahren wir mehr öffentliche Verkehrsmittel?',
        iconId: '',
        items: [
          {
            title: 'Nachfrage nach ÖPNV & Fernbahn',
            text: 'Die Nachfrage für den ÖPNV steigt bis 2030 leicht. Danach geht sie wieder etwas zurück, weil das Fahren mit dem eigenen Pkw wieder attraktiver wird, wenn zunehmend mit <a class="link" data-index="nullemissionsfahrzeug">Nullemissionsfahrzeugen</a> gefahren wird. Die Nachfrage nach Fernbahn-Reisen steigt bis 2045 um knapp 50%.',
            dataVisual: {
              type: 'bar',
              maxData: 110000000000,
              label: 'Personenkilometer',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Stadt', data: 100769895169 },
                    { label: 'Land', data: 21429048023 },
                    { label: 'Fernbahn', data: 40143546361 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Stadt', data: 108969742052 },
                    { label: 'Land', data: 20840322730 },
                    { label: 'Fernbahn', data: 60452752951 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Stadt', data: 96878252937 },
                    { label: 'Land', data: 17483841124 },
                    { label: 'Fernbahn', data: 69743337856 },
                  ]
                },
              ]
            }
          },
        ]
      },
      {
        title: 'Wer bewegt sich wie auf Stadt-/Landstrecken?',
        iconId: '',
        items: [
          {
            title: 'Verkehrsmix',
          },
          {
            title: 'Stadt',
            text: '',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 52 },
                    { label: 'Fuß', data: 25 },
                    { label: 'Rad', data: 13 },
                    { label: 'ÖPNV', data: 11 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 47 },
                    { label: 'Fuß', data: 27 },
                    { label: 'Rad', data: 15 },
                    { label: 'ÖPNV', data: 12 },
                  ]
                },
                {
                  label: '2045',
                  items: [
                    { label: 'Pkw', data: 47 },
                    { label: 'Fuß', data: 27 },
                    { label: 'Rad', data: 16 },
                    { label: 'ÖPNV', data: 10 },
                  ]
                },
              ]
            },
          },
          {
            title: 'Land',
            text: 'Die Stadtbewohner:innen reduzieren die Pkw-Nutzung etwas und steigen im Gegenzug auf Rad- oder Fußverkehr um. Die Nutzung öffentlicher Verkehrsmittel bleibt auf lange Sicht gleich und verlagert sich nur vorübergehend etwas auf den ÖPNV. Die Landbewohner:innen  ändern in diesem Pfad ihr Mobilitätsverhalten kaum.',
            dataVisual: {
              type: 'bar-stacked',
              label: ' ',
              items: [
                {
                  label: 'Jetzt',
                  items: [
                    { label: 'Pkw', data: 63 },
                    { label: 'Fuß', data: 21 },
                    { label: 'Rad', data: 10 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
                {
                  label: '2030',
                  items: [
                    { label: 'Pkw', data: 60 },
                    { label: 'Fuß', data: 23 },
                    { label: 'Rad', data: 12 },
                    { label: 'ÖPNV', data: 6 },
                  ]
                },
                {
                  label: '2040',
                  items: [
                    { label: 'Pkw', data: 61 },
                    { label: 'Fuß', data: 22 },
                    { label: 'Rad', data: 12 },
                    { label: 'ÖPNV', data: 5 },
                  ]
                },
              ]
            },
          }
        ]
      },
      // {
      //   title: 'Wieviel Stadt-/Landbewohner:innen haben eigene Autos?',
      //   iconId: '',
      //   items: [
      //     {
      //       title: 'Stadt',
      //       text: '',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 545
      //           },
      //           {
      //             label: '2030',
      //             data: 544
      //           },
      //           {
      //             label: '2045',
      //             data: 538
      //           },
      //         ]
      //       }
      //     },
      //     {
      //       title: 'Land',
      //       text: 'Die Anzahl der PKW in der Stadt und auf dem Land bleiben in etwa gleich.',
      //       dataVisual: {
      //         type: 'circle',
      //         label: 'Pkw/1.000 Einw.',
      //         items: [
      //           {
      //             label: 'Jetzt',
      //             data: 625
      //           },
      //           {
      //             label: '2030',
      //             data: 637
      //           },
      //           {
      //             label: '2045',
      //             data: 650
      //           },
      //         ]
      //       }
      //     },
      //   ]
      // },
    ],
  },
  button: 'Zurück zur Pfadübersicht',
  buttonLink: '/path-overview'
}
